import React, { useState, useEffect, Suspense, lazy } from 'react'
// Web application libraries
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'
// Redux actions
import {
    setFooter,
    setMenu,
    setSelectedMenu,
    setPages,
    setRoutes
} from '../actions'
import apiService from '../services/ApiService'
// Styles
import './App.css'
// Web application components
import Navbar from './generalComponents/Navbar'
import Footer from './generalComponents/Footer'
import Login from './Login'
import { adminRoutes, publicRoutes } from '../routes/routes'
import PrivateRoute from '../middlewares/privateRoutes'


const App = (props) => {

    const { languageWeb, routes } = props
    const [isLoading, setIsLoading] = useState(true)
    const [pathHomePage, setPathHomePage] = useState(routes.Traductions[languageWeb].Home)
    const [pathEntityPage, setPathEntityPage] = useState(routes.Traductions[languageWeb].Entity)
    const [pathPlantersPage, setPathPlantersPage] = useState(routes.Traductions[languageWeb].Planters)
    const [pathNetworkPage, setPathNetworkPage] = useState(routes.Traductions[languageWeb].Network)
    const [pathSupportsPage, setPathSupportsPage] = useState(routes.Traductions[languageWeb].Supports)
    const [pathArbrarProjectPage, setPathArbrarProjectPage] = useState(routes.Traductions[languageWeb].Arbrar)

    useEffect(() => {
        (async () => {
            await getAllTheWebResources()
        })()
    }, [])

    useEffect(() => {
        setPathHomePage(routes.Traductions[languageWeb].Home)
        setPathEntityPage(routes.Traductions[languageWeb].Entity)
        setPathPlantersPage(routes.Traductions[languageWeb].Planters)
        setPathNetworkPage(routes.Traductions[languageWeb].Network)
        setPathSupportsPage(routes.Traductions[languageWeb].Supports)
        setPathArbrarProjectPage(routes.Traductions[languageWeb].Arbrar)
    }, [languageWeb])

    const getAllTheWebResources = async () => {
        const menuPromise = apiService.getMenuData()
        const footerPromise = apiService.getFooterData()
        const routesPromise = apiService.getRoutes()
        const pagesPromise = apiService.getAllPages()
        const [footerData, menuData, routesData, pagesData] = await Promise.all([footerPromise, menuPromise, routesPromise, pagesPromise]);
        props.dispatchSetPages(pagesData)
        props.dispatchSetMenu(menuData)
        props.dispatchSetFooter(footerData)
        props.dispatchSetRoutes(routesData)
        setIsLoading(false)
    }

    if(!isLoading)
        if(!isMobileOnly)
            return (
                <>
                <Navbar />
                <div className='App_Main_Container'>
                    <Suspense fallback=''>
                        <Switch>
                            { 
                                publicRoutes.map((route) => (
                                    <Route key={route.path} {...route} />
                                ))
                            }
                            {
                                adminRoutes.map((route) => (
                                    <PrivateRoute key={route.path} {...route} />
                                ))
                            }
                            <Route exact path={'/admin'} component={Login} />
                        </Switch>
                    </Suspense>
                </div>
                <Footer/>
                </>
            )
        else 
        return (
            <>
                <Navbar />
                <div className='App_Main_Container'>
                    <Suspense fallback=''>
                        <Switch>
                            { 
                                publicRoutes.map((route) => (
                                    <Route key={route.path} {...route} />
                                ))
                            }
                        </Switch>
                    </Suspense>
                </div>
                <Footer />
        </>
        )
    else return null
    }

const mapStateToProps = state => ({
    selectedMenu: state.selectedMenu,
    languageWeb: state.languageWeb,
    routes: state.resources.Routes
})

const mapDispatchToProps = dispatch => (
    {
        dispatchSetFooter: value => dispatch(setFooter(value)),
        dispatchSetMenu: value => dispatch(setMenu(value)),
        dispatchSetRoutes: value => dispatch(setRoutes(value)),
        dispatchSetPages: value => dispatch(setPages(value)),
        dispatchSetSelectedMenu: value => dispatch(setSelectedMenu(value))
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(App)