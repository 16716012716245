import React, { useState } from 'react'

const LANGUAGES = ['Catalan', 'Spanish', 'English', 'French'];

const ProjectsHomeAdmin = (props) => {

    const { projects } = props

    const [selectedLanguageTab, setSelectedLanguageTab] = useState(
        'Catalan'
    )

    const [temporalProjects, setTemporalProjects] = useState(projects);

    const handleNetworkTabChange = (language) => {
        setSelectedLanguageTab(language);
    }

    const renderLanguageSection = (language) => {
        return (
            <div
                className={`tab-pane fade show ${
                    selectedLanguageTab === language ? "active" : ""
                }`}
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
                <div className='row mt-2 ml-1 mr-1'>
            {/* <div className="d-flex justify-content-end">
                <button className="btn btn-sm btn-primary mt-3" disabled={true} onClick={addNewProjectToHome}>
                        <i className="bi bi-plus"></i> <span style={{paddingLeft: '10px'}}>Afegir nou projecte</span>
                </button>
              </div> */}
              <div className="table-responsive mt-2">
                <table className="table table-sm">
                    <thead className="table-primary">
                        <tr>
                            <th className="border-top-0 border-bottom-0 text-center" scope="col">Posició</th>
                            <th className="border-top-0 border-bottom-0" scope="col">Títol</th>
                            <th className="border-top-0 border-bottom-0" scope="col">Descripció</th>
                            <th className="border-top-0 border-bottom-0" scope="col">Imatge</th>
                            <th className="border-top-0 border-bottom-0" scope="col">Enllaç</th>
                            <th className="border-top-0 border-bottom-0 text-right" colSpan="3"></th>
                        </tr>
                    </thead>
                        <tbody>
                          {temporalProjects.map((project, index) => {
                            return (
                              <tr key={index} className="animated fadeIn">
                                <td className="text-center">{`${project.Information.Position}`}</td>
                                <td>
                                  <textarea
                                    className="form-control"
                                    id="textAreaExample"
                                    rows={1}
                                    name="Title"
                                    value={
                                      project.Traductions[selectedLanguageTab].Title
                                    }
                                    onChange={(e) => handleChangeTitle(index,e)}
                                  ></textarea>
                                </td>
                                <td>
                                  <textarea
                                    className="form-control"
                                    id="textAreaExample"
                                    rows={4}
                                    name="Description"
                                    value={
                                      project.Traductions[selectedLanguageTab].Description
                                    }
                                    onChange={(e) => handleChangeDescription(index,e)}
                                  ></textarea>
                                </td>
                                <td>
                                  <textarea
                                    className="form-control"
                                    id="textAreaExample"
                                    rows={4}
                                    name="Image"
                                    value={
                                      project.Information.Image
                                    }
                                    onChange={(e) => handleChangeImage(index,e)}
                                  ></textarea>
                                </td>
                                <td>
                                    <select className="form-control" onChange={(event) => handleChangeLinkMenu(event, index)} value={project.Information.LinkMenu}>
                                        <option value="Entity">Entitat</option>
                                        <option value="Network">En xarxa</option>
                                        <option value="Planters">Planters</option>
                                        <option value="Support">Suport</option>
                                    </select>
                                    {project.Information.LinkMenu === "Planters" && (
                                      <select className="form-control mt-2" onChange={(event) => handleChangeLinkSubmenu(event, index)} value={project.Information.LinkSubmenu}>
                                        <option value="Planters">Planters</option>
                                        <option value="Schools">Centres educatius</option>
                                        <option value="ExtracurricularActivities">Activitats extraescolars</option>
                                        <option value="Formation">Formació</option>
                                        <option value="Productions">Produccions</option>
                                      </select>
                                    )}
                                </td>
                                <td className="text-end" style={{ width: '130px' }}>
                                    <button className="btn btn-sm btn-primary"
                                            disabled={index === 0}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                changeProjectPosition(index, 'Up')
                                            }}>
                                        <i class="bi bi-arrow-up"></i>
                                    </button>
                                    <button className="btn btn-sm btn-primary ml-2"
                                            disabled={(index+1)===temporalProjects.length}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                changeProjectPosition(index, 'Down')
                                            }}>
                                        <i class="bi bi-arrow-down"></i>
                                    </button>
                                    <button className="btn btn-sm btn-danger ml-2"
                                    disabled={true}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                removeProjectFromHome(index)
                                            }}>
                                        <i class="bi bi-trash3"></i>
                                    </button>
                                </td>
                              </tr>)
                          })}
                      </tbody>
                  </table>
              </div>
          </div>
                
                </div>
        )
    }

    const addNewProjectToHome = () => {
      const newProject = {
          "typeOfProject": "Home",
          "Information": {
              "Position": temporalProjects.length + 1,
              "LinkMenu": "",
              "LinkSubmenu": "",
              "LinkMobile": "",
              "Image": ""
          },
          "Traductions": {
              "Catalan": {
                  "Title": "",
                  "Description": "",
                  "Link": ""
              },
              "Spanish": {
                  "Title": "",
                  "Description": "",
                  "Link": ""
              },
              "English": {
                  "Title": "",
                  "Description": "",
                  "Link": ""
              },
              "French": {
                  "Title": "",
                  "Description": "",
                  "Link": ""
              }
          }
      };
  
      setTemporalProjects([...temporalProjects, newProject]);
    }
  
    const handleChangeTitle = (index, event) => {
      const newProjects = [...temporalProjects];  // Crea una copia del array de proyectos
      const project = newProjects[index];  // Obtiene el proyecto específico que se está editando

      if (project.Traductions[selectedLanguageTab]) {  // Verifica que la traducción para el lenguaje seleccionado existe
          project.Traductions[selectedLanguageTab].Title = event.target.value;  // Cambia el valor del título
      }

      setTemporalProjects(newProjects);  // Actualiza el estado con la nueva lista de proyectos
    }

    const handleChangeDescription = (index, event) => {
      const newProjects = [...temporalProjects];  // Crea una copia del array de proyectos
      const project = newProjects[index];  // Obtiene el proyecto específico que se está editando
  
      if (project.Traductions[selectedLanguageTab]) {  // Verifica que la traducción para el lenguaje seleccionado existe
          project.Traductions[selectedLanguageTab].Description = event.target.value;  // Cambia el valor de la descripción
      }
  
      setTemporalProjects(newProjects);  // Actualiza el estado con la nueva lista de proyectos
    }
  
    const handleChangeImage = (index, event) => {
      const newProjects = [...temporalProjects];  // Crea una copia del array de proyectos
      const project = newProjects[index];  // Obtiene el proyecto específico que se está editando
  
      project.Information.Image = event.target.value;  // Cambia el valor de la imagen
  
      setTemporalProjects(newProjects);  // Actualiza el estado con la nueva lista de proyectos
    }

    const handleChangeLinkMenu = (event, index) => {
      const newProjects = [...temporalProjects];
      const project = newProjects[index];
  
      if (project.Information.LinkMenu) {
          project.Information.LinkMenu = event.target.value;
          project.Information.LinkSubmenu = '';
      }
  
      setTemporalProjects(newProjects);
    }

    const handleChangeLinkSubmenu = (event, index) => {
      const newProjects = [...temporalProjects];
      const project = newProjects[index];
  
      if (project.Information.LinkMenu && project.Information.LinkMenu === 'Planters') { 
          project.Information.LinkSubmenu = event.target.value;
      }
  
      setTemporalProjects(newProjects);
    }

    const changeProjectPosition = (index, direction) => {
      const newProjects = [...temporalProjects];
      if ((direction === 'Up' && index === 0) || (direction === 'Down' && index === newProjects.length - 1)) {
          return;
      }
      
      const projectToMove = newProjects[index];
      
      if (direction === 'Up') {
          // Si estamos moviendo hacia arriba, intercambiamos con el elemento anterior
          newProjects[index] = newProjects[index - 1];
          newProjects[index - 1] = projectToMove;
      } else {
          // Si estamos moviendo hacia abajo, intercambiamos con el elemento siguiente
          newProjects[index] = newProjects[index + 1];
          newProjects[index + 1] = projectToMove;
      }
  
      // Actualizamos las posiciones
      newProjects.forEach((project, idx) => {
          project.Information.Position = idx + 1;
      });
  
      setTemporalProjects(newProjects);  // Actualiza el estado con la nueva lista de proyectos
    }
  
    const removeProjectFromHome = (index) => {
      // Utilizamos filter para obtener un nuevo array sin el proyecto que queremos eliminar
      const newProjects = temporalProjects.filter((_, idx) => idx !== index);
  
      // Después de eliminar, es recomendable actualizar las posiciones de los proyectos restantes
      newProjects.forEach((project, idx) => {
          project.Information.Position = idx + 1;
      });
  
      setTemporalProjects(newProjects);  // Actualizamos el estado con la nueva lista de proyectos
    }
  
    const handleSaveProjects = () => {
        props.handleSaveProjects(temporalProjects)
    }

    return (
        <div className="card m-4">
        <div className="card-header d-flex justify-content-between align-items-center py-1">
          <span className="me-auto">PÀGINES DESTACADES</span>
          <button type="button" onClick={handleSaveProjects} className="btn btn-success btn-rounded btn-sm">
          <i className="bi bi-save"></i> <span style={{paddingLeft: '10px'}}>Guardar</span>
          </button>
        </div>
        <div className="card-body">
          <div>
            <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'Catalan' ? "active" : ""
                  }`}
                  id="ex1-tab-1"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-1"
                  aria-selected={selectedLanguageTab === 'Catalan'}
                  onClick={() => handleNetworkTabChange('Catalan')}
                >
                  Català
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'Spanish' ? "active" : ""
                  }`}
                  id="ex1-tab-4"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-4"
                  aria-selected={selectedLanguageTab === 'Spanish'}
                  onClick={() => handleNetworkTabChange('Spanish')}
                >
                  Castellà
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'English' ? "active" : ""
                  }`}
                  id="ex1-tab-2"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-2"
                  aria-selected={selectedLanguageTab === 'English'}
                  onClick={() => handleNetworkTabChange('English')}
                >
                  Anglès
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'French' ? "active" : ""
                  }`}
                  id="ex1-tab-3"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-3"
                  aria-selected={selectedLanguageTab === 'French'}
                  onClick={() => handleNetworkTabChange('French')}
                >
                  Francès
                </button>
              </li>
            </ul>
            <div className="tab-content" id="ex1-content">
              {LANGUAGES.map(language => renderLanguageSection(language))}
            </div>
          </div>
        </div>
      </div>
    )
}

export default ProjectsHomeAdmin