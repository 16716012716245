import React, { useState } from 'react'

const SchoolsExtraPanelAdmin = (props) => {

    const { traductions } = props

    const [selectedLanguageTab, setSelectedLanguageTab] = useState(
        'Catalan'
    )

    const [temporalTraductions, setTemporalTraductions] = useState(traductions);

    const handleSelectedLanguageTabChange = (language) => {
        setSelectedLanguageTab(language);
    }

    const handleChangeInformation = (event) => {
      const { name, value } = event.target
      setTemporalTraductions((prevInformation) => ({
      ...prevInformation,
      [selectedLanguageTab]: {
          ...prevInformation[selectedLanguageTab],
          [name]: value,
      },
      }))
    }

    const renderLanguageSection = (language) => {
        return (
            <div
                className={`tab-pane fade show ${
                    selectedLanguageTab === language ? "active" : ""
                }`}
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
                {/* <div className="row">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Títol
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="TitlePart1"
                        value={
                          temporalTraductions[selectedLanguageTab].TitlePart1
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Títol
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="TitlePart2"
                        value={
                          temporalTraductions[selectedLanguageTab].TitlePart2
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Descripció
                      </label>
                      <textarea
                        className="form-control"
                        id="textAreaExample"
                        rows={6}
                        name="Description"
                        value={
                          temporalTraductions[selectedLanguageTab].Description
                        }
                        onChange={handleChangeInformation}
                      ></textarea>
                    </div>
                  </div>
                </div> */}

                <div className="row mt-2">
                  <div className="col-md-6">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Centres educatius
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ParticipantsTitle"
                        value={
                          temporalTraductions[selectedLanguageTab].ParticipantsTitle
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                </div>

                {/* TABLA PARA EL CONTROL DE CENTROS */}

                <div className='row mt-2 ml-1 mr-1'>
                  <button className="btn btn-sm btn-primary mt-3" onClick={handleAddNewParticipant}>
                      <i className="bi bi-plus"></i> <span style={{paddingLeft: '10px'}}>Afegir</span>
                  </button>
                  <div className="table-responsive">
                    <table className="table table-sm">
                        <thead className="table-primary">
                            <tr>
                                <th className="border-top-0 border-bottom-0" scope="col">Centre</th>
                                <th className="border-top-0 border-bottom-0 text-center" scope="col">Posició</th>
                                <th className="border-top-0 border-bottom-0"></th>
                                <th className="border-top-0 border-bottom-0"></th>
                                <th className="border-top-0 border-bottom-0"></th>
                            </tr>
                        </thead>
                            <tbody>
                              {temporalTraductions[selectedLanguageTab].Participants.map((school, index) => {
                                return (
                                  <tr key={index} className="animated fadeIn">
                                    <textarea
                                      className="form-control"
                                      id="textAreaExample"
                                      rows={1}
                                      name="Inspirers"
                                      value={
                                        school
                                      }
                                      onChange={(e) => {
                                        handleChangeParticipant(index,e)
                                      }}
                                    ></textarea>
                                    <td className="text-center">{`${index + 1}`}</td>
                                    <td>
                                        <button className="btn btn-sm btn-primary"
                                                disabled={index === 0}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    handleChangeParticipantPosition(index, 'Up')
                                                }}>
                                            <i class="bi bi-arrow-up"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button className="btn btn-sm btn-primary"
                                                disabled={(index+1)===temporalTraductions[selectedLanguageTab].Participants.length}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    handleChangeParticipantPosition(index, 'Down')
                                                }}>
                                            <i class="bi bi-arrow-down"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button className="btn btn-sm btn-danger"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    handleremoveParticipantPosition(index)
                                                }}>
                                            <i class="bi bi-trash3"></i>
                                        </button>
                                    </td>
                                  </tr>
                                  
                                )
                              })}
                          </tbody>
                      </table>
                  </div>

                </div>

                <div className="row mt-2">
                  <div className="col-md-6">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Artistes
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="AnotherParticipantsTitle"
                        value={
                          temporalTraductions[selectedLanguageTab].AnotherParticipantsTitle
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                </div>

                 {/* TABLA PARA EL CONTROL DE OTROS PARTICIPANTES */}

                 <div className='row mt-2 ml-1 mr-1'>
                  <button className="btn btn-sm btn-primary mt-3" onClick={handleAddNewAnotherParticipant}>
                      <i className="bi bi-plus"></i> <span style={{paddingLeft: '10px'}}>Afegir</span>
                  </button>
                  <div className="table-responsive">
                    <table className="table table-sm">
                        <thead className="table-primary">
                            <tr>
                                <th className="border-top-0 border-bottom-0" scope="col">Centre</th>
                                <th className="border-top-0 border-bottom-0 text-center" scope="col">Posició</th>
                                <th className="border-top-0 border-bottom-0"></th>
                                <th className="border-top-0 border-bottom-0"></th>
                                <th className="border-top-0 border-bottom-0"></th>
                            </tr>
                        </thead>
                            <tbody>
                              {temporalTraductions[selectedLanguageTab].AnotherParticipants.map((person, index) => {
                                return (
                                  <tr key={index} className="animated fadeIn">
                                    <textarea
                                      className="form-control"
                                      id="textAreaExample"
                                      rows={1}
                                      name="AnotherParticipants"
                                      value={
                                        person
                                      }
                                      onChange={(e) => {
                                        handleChangeAnotherParticipantInformation(index,e)
                                      }}
                                    ></textarea>
                                    <td className="text-center">{`${index + 1}`}</td>
                                    <td>
                                        <button className="btn btn-sm btn-primary"
                                                disabled={index === 0}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    handleChangeAnotherParticipantPosition(index, 'Up')
                                                }}>
                                            <i class="bi bi-arrow-up"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button className="btn btn-sm btn-primary"
                                                disabled={(index+1)===temporalTraductions[selectedLanguageTab].AnotherParticipants.length}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    handleChangeAnotherParticipantPosition(index, 'Down')
                                                }}>
                                            <i class="bi bi-arrow-down"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button className="btn btn-sm btn-danger"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    handleRemoveAnotherParticipant(index)
                                                }}>
                                            <i class="bi bi-trash3"></i>
                                        </button>
                                    </td>
                                  </tr>
                                  
                                )
                              })}
                          </tbody>
                      </table>
                  </div>

                </div>
                
            </div>
        )
    }

    const handleSaveTitleAndSubtitle = () => {
        props.handleSaveTitleAndSubtitle(temporalTraductions)
    }

    const handleAddNewParticipant = () => {
      const newParticipant = '';
      const updatedParticipants = [...temporalTraductions[selectedLanguageTab].Participants, newParticipant];

      setTemporalTraductions(prevState => ({
        ...prevState,
        [selectedLanguageTab]: {
          ...prevState[selectedLanguageTab],
          Participants: updatedParticipants,
        },
      }));
    };

    const handleChangeParticipant = (index, e) => {
      const updatedParticipants = [...temporalTraductions[selectedLanguageTab].Participants];
      updatedParticipants[index] = e.target.value;

      setTemporalTraductions(prevState => ({
        ...prevState,
        [selectedLanguageTab]: {
          ...prevState[selectedLanguageTab],
          Participants: updatedParticipants,
        },
      }));
    };

  const handleChangeParticipantPosition = (index, direction) => {
    const updatedParticipants = [...temporalTraductions[selectedLanguageTab].Participants];

    if (direction === 'Up' && index > 0) {
      // Intercambiar el participante con el de arriba.
      const temp = updatedParticipants[index];
      updatedParticipants[index] = updatedParticipants[index - 1];
      updatedParticipants[index - 1] = temp;
    } else if (direction === 'Down' && index < updatedParticipants.length - 1) {
      // Intercambiar el participante con el de abajo.
      const temp = updatedParticipants[index];
      updatedParticipants[index] = updatedParticipants[index + 1];
      updatedParticipants[index + 1] = temp;
    }

    setTemporalTraductions(prevState => ({
      ...prevState,
      [selectedLanguageTab]: {
        ...prevState[selectedLanguageTab],
        Participants: updatedParticipants,
      },
    }));
  };

    const handleremoveParticipantPosition = (index) => {
      const updatedParticipants = [...temporalTraductions[selectedLanguageTab].Participants];

      // Elimina el participante en el índice especificado.
      updatedParticipants.splice(index, 1);

      // Actualiza el estado con la nueva lista de participantes.
      // setTraductions debe ser la función que actualiza el estado de los participantes.
      setTemporalTraductions(prevState => ({
        ...prevState,
        [selectedLanguageTab]: {
          ...prevState[selectedLanguageTab],
          Participants: updatedParticipants,
        },
      }));
    };

    const handleAddNewAnotherParticipant = () => {
      const newParticipant = ''; // Puedes establecer un valor predeterminado para el nuevo participante aquí.
      const updatedParticipants = [...temporalTraductions[selectedLanguageTab].AnotherParticipants, newParticipant];
      setTemporalTraductions(prevState => ({
        ...prevState,
        [selectedLanguageTab]: {
          ...prevState[selectedLanguageTab],
          AnotherParticipants: updatedParticipants,
        },
      }));
    };

    const handleRemoveAnotherParticipant = (index) => {
      const updatedParticipants = [...temporalTraductions[selectedLanguageTab].AnotherParticipants];

      // Elimina el participante en el índice especificado.
      updatedParticipants.splice(index, 1);

      // Actualiza el estado con la nueva lista de participantes.
      // setTraductions debe ser la función que actualiza el estado de los participantes.
      setTemporalTraductions(prevState => ({
        ...prevState,
        [selectedLanguageTab]: {
          ...prevState[selectedLanguageTab],
          AnotherParticipants: updatedParticipants,
        },
      }));
    };

    const handleChangeAnotherParticipantInformation = (index, e) => {
      const updatedParticipants = [...temporalTraductions[selectedLanguageTab].AnotherParticipants];
      updatedParticipants[index] = e.target.value;

      setTemporalTraductions(prevState => ({
        ...prevState,
        [selectedLanguageTab]: {
          ...prevState[selectedLanguageTab],
          AnotherParticipants: updatedParticipants,
        },
      }));
    };

    const handleChangeAnotherParticipantPosition = (index, direction) => {
      const updatedParticipants = [...temporalTraductions[selectedLanguageTab].AnotherParticipants];

      if (direction === 'Up' && index > 0) {
        // Intercambiar el participante con el de arriba.
        const temp = updatedParticipants[index];
        updatedParticipants[index] = updatedParticipants[index - 1];
        updatedParticipants[index - 1] = temp;
      } else if (direction === 'Down' && index < updatedParticipants.length - 1) {
        // Intercambiar el participante con el de abajo.
        const temp = updatedParticipants[index];
        updatedParticipants[index] = updatedParticipants[index + 1];
        updatedParticipants[index + 1] = temp;
      }

      // setTraductions debe ser la función que actualiza el estado de los participantes.
      setTemporalTraductions(prevState => ({
        ...prevState,
        [selectedLanguageTab]: {
          ...prevState[selectedLanguageTab],
          AnotherParticipants: updatedParticipants,
        },
      }));
    };

    return (
        <div className="card m-4">
        <div className="card-header d-flex justify-content-between align-items-center py-1">
          <span className="me-auto ">ALTRES CENTRES EDUCATIUS I ARTISTES PARTICIPANTS</span>
          <button type="button" onClick={handleSaveTitleAndSubtitle} className="btn btn-success btn-rounded btn-sm">
          <i className="bi bi-save"></i> <span style={{paddingLeft: '10px'}}>Guardar</span>
          </button>
        </div>
        <div className="card-body">
          <div>
            <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'Catalan' ? "active" : ""
                  }`}
                  id="ex1-tab-1"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-1"
                  aria-selected={selectedLanguageTab === 'Catalan'}
                  onClick={(event) => handleSelectedLanguageTabChange('Catalan')}
                >
                  Català
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'Spanish' ? "active" : ""
                  }`}
                  id="ex1-tab-4"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-4"
                  aria-selected={selectedLanguageTab === 'Spanish'}
                  onClick={(event) => handleSelectedLanguageTabChange('Spanish')}
                >
                  Castellà
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'English' ? "active" : ""
                  }`}
                  id="ex1-tab-2"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-2"
                  aria-selected={selectedLanguageTab === 'English'}
                  onClick={(event) => handleSelectedLanguageTabChange('English')}
                >
                  Anglès
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'French' ? "active" : ""
                  }`}
                  id="ex1-tab-3"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-3"
                  aria-selected={selectedLanguageTab === 'French'}
                  onClick={(event) => handleSelectedLanguageTabChange('French')}
                >
                  Francès
                </button>
              </li>
            </ul>
            {/* Tabs content */}
            <div className="tab-content" id="ex1-content">
              {renderLanguageSection('Catalan')}
              {renderLanguageSection('Spanish')}
              {renderLanguageSection('English')}
              {renderLanguageSection('French')}
            </div>
          </div>
        </div>
      </div>
    )
}

export default SchoolsExtraPanelAdmin