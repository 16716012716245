import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import App from './components/App'
import { Provider } from 'react-redux'
import { store } from './store/redux'
import * as serviceWorker from './serviceWorker'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './index.css'

ReactDOM.render(
    <Suspense fallback=''>
        <Provider store={store}>
            <Router>
                <Route path="/" render={(props) => <App {...props} authed={true} />} />  
            </Router>
        </Provider>
    </Suspense>,
    document.getElementById('root')
)

serviceWorker.unregister()

// Para subir a mi subdominio:

// homePage: 'http://conarteinternacional.koldocastillo.com'

// No subir el .htaccess