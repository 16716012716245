import axios from 'axios';
import config from '../../config';

class ApiService {
  constructor() {
    this.baseURL = config.url.Development
  }

  getHeader() {
    return {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  async get(endpoint) {
    const url = `${this.baseURL}/${endpoint}`;
    return await axios.get(url, this.getHeader());
  }

  async post(endpoint, data) {
    const url = `${this.baseURL}/${endpoint}`;
    const response = await axios.post(url, data, this.getHeader());
    return response.data;
  }

  async getMenuData() {
    const response = await this.get('resources/menu');
    return response.data.result;
  }

  async getFooterData() {
    const response = await this.get('resources/footer');
    return response.data.result;
  }

  async getRoutes() {
    const response = await this.get('resources/routes');
    return response.data.result;
  }

  async getAllPages() {
    const response = await this.get('pages/list');
    return response.data.result;
  }

  async updatePage(item) {
    const response = await this.post('pages/updatePage', item);
    return response;
  }

  async authenticateUser(item) {
    const response = await this.post('users/authenticate', item);
    return response;
  }

}

export default new ApiService();
