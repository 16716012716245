
export const SET_MENU = 'SET_MENU',
SET_FOOTER = 'SET_FOOTER',
SET_PAGES = 'SET_PAGES',
SET_ROUTES = 'SET_ROUTES',
SET_LOGO_AND_IDENTITY = 'SET_LOGO_AND_IDENTITY',
SET_LANGUAGE = 'SET_LANGUAGE',
SET_SELECTED_ROUTE = 'SET_SELECTED_ROUTE',
SET_SELECTED_MENU = 'SET_SELECTED_MENU',
SET_SELECTED_SUBMENU = 'SET_SELECTED_SUBMENU',
SET_IS_TOP_ON_THE_WINDOW = 'SET_IS_TOP_ON_THE_WINDOW',
SET_IS_ANIMATION = 'SET_IS_ANIMATION',
SET_ARBRAR_POSITION = 'SET_ARBRAR_POSITION'

export const setMenu = payload => ({type: SET_MENU, payload }),
setFooter = payload => ({type: SET_FOOTER, payload }),
setPages = payload => ({type: SET_PAGES, payload }),
setRoutes = payload => ({type: SET_ROUTES, payload }),
setLogoAndIdentity = payload => ({ type: SET_LOGO_AND_IDENTITY, payload }),
setLanguage = payload => ({type: SET_LANGUAGE, payload}),
setSelectedRoute = payload => ({type: SET_SELECTED_ROUTE, payload}),
setSelectedMenu = payload => ({ type: SET_SELECTED_MENU, payload }),
setSelectedSubmenu = payload => ({ type: SET_SELECTED_SUBMENU, payload }),
setIsTopOnTheWindow = payload => ({ type: SET_IS_TOP_ON_THE_WINDOW, payload }),
setIsAnimation = payload => ({ type: SET_IS_ANIMATION, payload }),
setArbrarPosition = payload => ({ type: SET_ARBRAR_POSITION, payload})