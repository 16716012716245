import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './NavigationBarAdmin.css'

const NavigationBarAdmin = (props) => {

    const handleLogout = () => {
        localStorage.removeItem('jwtToken');
        props.history.push('/admin');
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light NavigationBarAdminStyle">
            <div className="container-fluid p-0 m-0">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link pl-0" to="/">ConArte Internacional</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link`} to="/admin/home">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link`} to="/admin/entitat">Entitat</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className={`nav-link dropdown-toggle`} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Planters
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <Link className={`dropdown-item`}  to="/admin/planters">Planters</Link>
                                <Link className={`dropdown-item`} to="/admin/centres-educatius">Centres educatius</Link>
                                <Link className={`dropdown-item`} to="/admin/activitats-extraescolars">Activitats extraescolars</Link>
                                <Link className={`dropdown-item`} to="/admin/formacio">Formació</Link>
                                <Link className={`dropdown-item`} to="/admin/produccions">Produccions</Link>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/admin/en-xarxa">En xarxa</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/admin/suports">Suports</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Altres
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <Link className="dropdown-item" to="/admin/menu">Menú</Link>
                                <Link className="dropdown-item" to="/admin/footer">Footer</Link>
                            </div>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link disabled" to="/admin/arbrar">Arbrar</Link>
                        </li> */}
                    </ul>
                </div>
                <button type="button" onClick={handleLogout} className="btn btn-danger btn-rounded btn-sm">
                    <i class="bi bi-box-arrow-right"></i><span style={{paddingLeft: '10px'}}>Logout</span>
                </button>
            </div>
        </nav>
    );
}

export default connect(null, null)(withRouter(NavigationBarAdmin))