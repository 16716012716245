import React, { useState } from 'react'

const InspiradoresPanelAdmin = (props) => {

    const { traductions, inspiring } = props

    const [selectedLanguageTab, setSelectedLanguageTab] = useState(
        'Catalan'
    )

    const [information, setInformation] = useState(traductions)
    const [inspirers, setInspirers] = useState(inspiring)

    const handleLanguageTabChange = (language) => {
      setSelectedLanguageTab(language);
    };

    const handleChangeInformation = (event) => {

      const { name, value } = event.target;

      setInformation((prevInformation) => ({
      ...prevInformation,
      [selectedLanguageTab]: {
          ...prevInformation[selectedLanguageTab],
          [name]: value,
      },
      }));
  }

  const addNewInspirer = () => {
      setInspirers(prevInspirers => [...prevInspirers, ""]); 
  };


  const changeInspiringPeoplePosition = (index, direction) => {
    const newInspirers = [...inspirers];
    if (direction === 'Up' && index > 0) {
        [newInspirers[index], newInspirers[index - 1]] = [newInspirers[index - 1], newInspirers[index]];
    } else if (direction === 'Down' && index < newInspirers.length - 1) {
        [newInspirers[index], newInspirers[index + 1]] = [newInspirers[index + 1], newInspirers[index]];
    }
    setInspirers(newInspirers);
  };


  const removeInspiringPeople = (index) => {
    const newInspirers = [...inspirers];
    newInspirers.splice(index, 1);
    setInspirers(newInspirers);
  };


  const handleChangeInspiringPeopleInformation = (index, e) => {
    const newInspirers = [...inspirers];
    newInspirers[index] = e.target.value;
    setInspirers(newInspirers);
  };



    return (
        <div className="card m-4">
        <div className="card-header d-flex justify-content-between align-items-center py-1">
          <span className="me-auto">INSPIRADORES</span>
          <button type="button" onClick={() => props.handleSaveTraductionsAndInspirational(information, inspirers)} className="btn btn-success btn-rounded btn-sm">
          <i className="bi bi-save"></i> <span style={{paddingLeft: '10px'}}>Guardar</span>
          </button>
        </div>
        <div className="card-body">
          <div>
            <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'Catalan' ? "active" : ""
                  }`}
                  id="ex1-tab-1"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-1"
                  aria-selected={selectedLanguageTab === 'Catalan'}
                  onClick={(event) => handleLanguageTabChange('Catalan')}
                >
                  Català
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'Spanish' ? "active" : ""
                  }`}
                  id="ex1-tab-4"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-4"
                  aria-selected={selectedLanguageTab === 'Spanish'}
                  onClick={(event) => handleLanguageTabChange('Spanish')}
                >
                  Castellà
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'English' ? "active" : ""
                  }`}
                  id="ex1-tab-2"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-2"
                  aria-selected={selectedLanguageTab === 'English'}
                  onClick={(event) => handleLanguageTabChange('English')}
                >
                  Anglès
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'French' ? "active" : ""
                  }`}
                  id="ex1-tab-3"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-3"
                  aria-selected={selectedLanguageTab === 'French'}
                  onClick={(event) => handleLanguageTabChange('French')}
                >
                  Francès
                </button>
              </li>
              
            </ul>
            {/* Tabs content */}
            <div className="tab-content" id="ex1-content">
              <div
                className={`tab-pane fade show ${
                    selectedLanguageTab === 'Catalan' ? "active" : ""
                }`}
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Títol
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="Inspiring"
                        value={
                          information[selectedLanguageTab].Inspiring
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                </div>
                </div>
              <div
                className={`tab-pane fade show ${
                    selectedLanguageTab === 'Spanish' ? "active" : ""
                }`}
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Títol
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="Inspiring"
                        value={
                          information[selectedLanguageTab].Inspiring
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`tab-pane fade show ${
                    selectedLanguageTab === 'English' ? "active" : ""
                }`}
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Títol
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="Inspiring"
                        value={
                          information[selectedLanguageTab].Inspiring
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`tab-pane fade show ${
                    selectedLanguageTab === 'French' ? "active" : ""
                }`}
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Títol
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="Inspiring"
                        value={
                          information[selectedLanguageTab].Inspiring
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-2 ml-1 mr-1'>
                  <button className="btn btn-sm btn-primary mt-3" onClick={addNewInspirer}>
                      <i className="bi bi-plus"></i> <span style={{paddingLeft: '10px'}}>Afegir</span>
                  </button>
                  <div className="table-responsive">
                    <table className="table table-sm">
                        <thead className="table-primary">
                            <tr>
                                <th className="border-top-0 border-bottom-0" scope="col">Persona</th>
                                <th className="border-top-0 border-bottom-0 text-center" scope="col">Posició</th>
                                <th className="border-top-0 border-bottom-0"></th>
                                <th className="border-top-0 border-bottom-0"></th>
                                <th className="border-top-0 border-bottom-0"></th>
                            </tr>
                        </thead>
                            <tbody>
                              {inspirers.map((person, index) => {
                                return (
                                  <tr key={index} className="animated fadeIn">
                                    <textarea
                                      className="form-control"
                                      id="textAreaExample"
                                      rows={1}
                                      name="Inspirers"
                                      value={
                                        person
                                      }
                                      onChange={(e) => handleChangeInspiringPeopleInformation(index,e)}
                                    ></textarea>
                                    <td className="text-center">{`${index + 1}`}</td>
                                    <td>
                                        <button className="btn btn-sm btn-primary"
                                                disabled={index === 0}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    changeInspiringPeoplePosition(index, 'Up')
                                                }}>
                                            <i class="bi bi-arrow-up"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button className="btn btn-sm btn-primary"
                                                disabled={(index+1)===inspirers.length}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    changeInspiringPeoplePosition(index, 'Down')
                                                }}>
                                            <i class="bi bi-arrow-down"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button className="btn btn-sm btn-danger"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    removeInspiringPeople(index)
                                                }}>
                                            <i class="bi bi-trash3"></i>
                                        </button>
                                    </td>
                                  </tr>
                                  
                                )
                              })}
                          </tbody>
                      </table>
                  </div>

            </div>
          </div>
        </div>
      </div>
    )
}

export default InspiradoresPanelAdmin