import React, { useState } from 'react';
import convertDropboxLinkToImage from '../../../../../utils/DropboxLinkConverter';

const SupportByLogos = (props) => {
    const { supportedBy, handleSaveSupportImages } = props;

    const [temporalCollaborators, setTemporalCollaborators] = useState(supportedBy)
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCollaborator, setSelectedCollaborator] = useState(null);
    const [editedLink, setEditedLink] = useState('');
    const [editedDescription, setEditedDescription] = useState('');

    const handleSaveImages = () => {
    
        // Verificar si los seis últimos colaboradores tienen Link y Description vacíos
        const lastSixCollaborators = temporalCollaborators.slice(-6);
        const allEmpty = lastSixCollaborators.every(collaborator => collaborator.Link === '' && collaborator.Description === '');
    
        // Si todos los colaboradores están vacíos, eliminar la última fila
        if (allEmpty) {
            const updatedCollaborators = temporalCollaborators.slice(0, -6);
            setTemporalCollaborators(updatedCollaborators);
            handleSaveSupportImages(updatedCollaborators)
        } else {
            handleSaveSupportImages(temporalCollaborators)
        }

    };
    
    const handleEditCollaborator = (collaborator) => {
        setSelectedCollaborator(collaborator);
        setEditedLink(collaborator.Link || '');
        setEditedDescription(collaborator.Description || '');
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedCollaborator(null);
        setModalOpen(false);
    };

    const handleSaveChanges = () => {
        // Actualiza el colaborador editado en la lista de colaboradores
        const updatedCollaborators = temporalCollaborators.map((collaborator, index) => {
            if (index === selectedCollaborator.index) {
                return {
                    ...collaborator,
                    Link: editedLink,
                    Description: editedDescription
                };
            }
            return collaborator;
        });
        setTemporalCollaborators(updatedCollaborators);
        closeModal();
    };

    const handleDelete = () => {
        // Actualizar temporalCollaborators con Link y Description vacíos
        const updatedCollaborators = temporalCollaborators.map((collaborator, index) => {
            if (index === selectedCollaborator.index) {
                return {
                    ...collaborator,
                    Link: '',
                    Description: ''
                };
            }
            return collaborator;
        });
        setTemporalCollaborators(updatedCollaborators);
    
        // Limpiar los campos de edición
        setEditedLink('');
        setEditedDescription('');
    };
    

    const handleAddRow = () => {
        const emptyCollaborator = { Link: '', Description: '' };
        const newCollaborators = Array(6).fill(emptyCollaborator);
        setTemporalCollaborators(prevCollaborators => [...prevCollaborators, ...newCollaborators]);
    };
    

    const renderCollaboratorsInDesktop = () => {
        const rows = [];

        for (let i = 0; i < temporalCollaborators.length; i += 6) {
            const row = [];
            for (let j = i; j < i + 6 && j < temporalCollaborators.length; j++) {
                const collaborator = temporalCollaborators[j];
                const link = collaborator.Link || '';
                const description = collaborator.Description || '';
                const imageSrc = link ? convertDropboxLinkToImage(link) : '';

                row.push(
                    <div class="card col-2 d-flex flex-column" key={j}>
                        {imageSrc && (
                            <img src={imageSrc} alt={description} />
                        )}
                        <div class="card-body d-flex flex-column justify-content-between">
                            <div class="d-flex justify-content-center mt-auto">
                            <button className="btn btn-primary" onClick={() => handleEditCollaborator({ ...collaborator, index: j })}>Editar</button>
                            </div>
                        </div>
                    </div>
                );
            }
            const rowStyle = i === 0 ? { paddingTop: '20px' } : {};
            rows.push(
                <div className="row g-0 m-0 p-0" style={rowStyle} key={i}>
                    {row}
                </div>
            );
        }
        return rows;
    };

    // Verificar si al menos un colaborador tiene Link y Description no vacíos
    const canAddRow = temporalCollaborators.slice(-6).some(collaborator => collaborator.Link && collaborator.Description);

    return (
        <div className="card m-4">
            <div className="card-header d-flex justify-content-between align-items-center py-1">
                <span className="me-auto font-weight-bold">LOGOS DE SUPORT</span>
                <button type="button" onClick={handleSaveImages} className="btn btn-success btn-rounded btn-sm">
                    <i className="bi bi-save"></i> <span style={{ paddingLeft: '10px' }}>Guardar</span>
                </button>
            </div>
            <div className="card-body">
                {renderCollaboratorsInDesktop()}
            </div>
            <div className="card-footer">
                <button type="button" onClick={handleAddRow} className="btn btn-primary" disabled={!canAddRow}>Afegir una nova fila</button>
            </div>
            {/* Modal para editar colaborador */}
            {selectedCollaborator && (
                <div className="modal" style={{ display: modalOpen ? 'block' : 'none' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Editar imatge</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="linkInput" className="form-label">Link:</label>
                                    <input type="text" className="form-control" id="linkInput" value={editedLink} onChange={(e) => setEditedLink(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="descriptionInput" className="form-label">Descripció:</label>
                                    <input type="text" className="form-control" id="descriptionInput" value={editedDescription} onChange={(e) => setEditedDescription(e.target.value)} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancelar</button>
                                <button type="button" className="btn btn-danger" onClick={handleDelete}>Eliminar</button>
                                <button type="button" className="btn btn-primary" onClick={handleSaveChanges}>Guardar canvis</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SupportByLogos;
