import React from 'react'
import { Link } from 'react-router-dom'
import './Submenu.css'

const Submenu = (props) => {

    const { handleActiveSubmenu, languageWeb, routes, selectedSubmenu, traductions } = props

    const renderSubmenu = () => {
        return (
            <div className="row Submenu_Style Submenu_View fixed-top">
                <div className="col-12 Submenu_Menu_Justify_Content">
                    <ul className='Submenu_List_Style'>
                        <li>
                            <Link className={`nav-link effect Submenu_Link_Style ${selectedSubmenu === 'Schools' ? 'Submenu_Link_Bullet' : 'Submenu_Link_Bullet_Hover'}`}
                                  to={routes.Traductions[languageWeb].Planters}
                                  onClick={handleActiveSubmenu}
                                  name={"Schools"}>{traductions[languageWeb].SchoolsMenu}</Link>
                        </li>
                        <li>
                            <Link className={`nav-link effect Submenu_Link_Style ${selectedSubmenu === 'Extracurricular activities' ? 'Submenu_Link_Bullet' : 'Submenu_Link_Bullet_Hover'}`}
                                  to={routes.Traductions[languageWeb].Planters}
                                  onClick={handleActiveSubmenu}
                                  name={"Extracurricular activities"}>{traductions[languageWeb].ExtracurricularActivitiesMenu}</Link>
                        </li>
                        <li>
                            <Link className={`nav-link effect Submenu_Link_Style ${selectedSubmenu === 'Formation' ? 'Submenu_Link_Bullet' : 'Submenu_Link_Bullet_Hover'}`}
                                  to={routes.Traductions[languageWeb].Planters}
                                  onClick={handleActiveSubmenu}
                                  name={"Formation"}>{traductions[languageWeb].FormationMenu}</Link>
                        </li>
                        <li>
                            <Link className={`nav-link effect Submenu_Link_Style ${selectedSubmenu === 'Productions' ? 'Submenu_Link_Bullet' : 'Submenu_Link_Bullet_Hover'}`}
                                  to={routes.Traductions[languageWeb].Planters}
                                  onClick={handleActiveSubmenu}
                                  name={"Productions"}>{traductions[languageWeb].ProductionsMenu}</Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <>
            {renderSubmenu()}
        </>
    )
}

export default Submenu

// const renderPlantersMenú = () => {
  //   return (
  //     <div className="row fixed-top Navbar_Planters_Style Navbar_Desktop_View">
  //       <div className="col-12 Navbar_Menu_JustifyContent">
  //         <ul className='Navbar pl-0'>
  //           <li className="nav-item">
  //             <HashLink className={`nav-link effect Navbar_Subenu_LinkText ${selectedSubmenu === 'Schools' ? 'Navbar_LinkText_Bullet_Submenu' : 'Navbar_LinkText_Bullet_Submenu_Hover'}`}
  //                   to={routes[languageWeb].Planters + '/#schools' }
  //                   tabIndex='1'
  //                   onClick={event => handleActiveMenuAndSubmenu(event, 'Schools')}
  //                   disabled={true}
  //                   name={"Planters"}>{traductions[props.languageWeb].Schools}</HashLink>
  //           </li>
  //           <li className="nav-item">
  //             <HashLink className={`nav-link effect Navbar_Subenu_LinkText ${selectedSubmenu === 'Extracurricular activities' ? 'Navbar_LinkText_Bullet_Submenu' : 'Navbar_LinkText_Bullet_Submenu_Hover'}`}
  //                   to={routes[languageWeb].Planters + '/#activities'}
  //                   onClick={event =>  handleActiveMenuAndSubmenu(event, 'Extracurricular activities')}
  //                   disabled={true}
  //                   name={"Planters"}>{traductions[props.languageWeb].ExtracurricularActivities}</HashLink>
  //           </li>
  //           <li className="nav-item">
  //             <HashLink className={`nav-link effect Navbar_Subenu_LinkText ${selectedSubmenu === 'Formation' ? 'Navbar_LinkText_Bullet_Submenu' : 'Navbar_LinkText_Bullet_Submenu_Hover'}`}
  //                   to={routes[languageWeb].Planters + '/#formation'}
  //                   onClick={event => handleActiveMenuAndSubmenu(event, 'Formation')}
  //                   disabled={true}
  //                   name={"Planters"}>{traductions[props.languageWeb].Formation}</HashLink>
  //           </li>
  //           <li className="nav-item">
  //             <HashLink className={`nav-link effect Navbar_Subenu_LinkText ${selectedSubmenu === 'Productions' ? 'Navbar_LinkText_Bullet_Submenu' : 'Navbar_LinkText_Bullet_Submenu_Hover'}`}
  //                   to={routes[languageWeb].Planters + '/#productions'}
  //                   onClick={event => handleActiveMenuAndSubmenu(event, 'Productions')}
  //                   disabled={true}
  //                   name={"Planters"}>{traductions[props.languageWeb].Productions}</HashLink>
  //           </li>
  //         </ul>
  //       </div>
  //     </div>
  //   )
  // }