import React, { useState } from 'react'

const EntityPanelAdmin = (props) => {

    const { traductions } = props

    const [selectedLanguageTab, setSelectedLanguageTab] = useState(
        'Catalan'
    )

    const [information, setInformation] = useState(traductions);

    const handleNetworkTabChange = (language) => {
        setSelectedLanguageTab(language);
    };

    const handleChangeInformation = (event) => {

        const { name, value } = event.target;

        setInformation((prevInformation) => ({
        ...prevInformation,
        [selectedLanguageTab]: {
            ...prevInformation[selectedLanguageTab],
            [name]: value,
        },
        }));
    }

    const addAxeOfAction = () => {
      const updatedInformation = { ...information };
        if (!updatedInformation[selectedLanguageTab].AxesOfAction) {
            updatedInformation[selectedLanguageTab].AxesOfAction = [];
        }
        updatedInformation[selectedLanguageTab].AxesOfAction.push('');
        setInformation(updatedInformation); 
    }

    const changeAxesOfActionPosition = (index, direction) => {
      const newTraductions = { ...information };
      const list = newTraductions[selectedLanguageTab].AxesOfAction;
      if (direction === 'Up' && index > 0) {
        const temp = list[index];
        list[index] = list[index - 1];
        list[index - 1] = temp;
      }
      if (direction === 'Down' && index < list.length - 1) {
        const temp = list[index];
        list[index] = list[index + 1];
        list[index + 1] = temp;
      }
      setInformation(newTraductions)
    }

    const removeAxeOfAction = (index) => {
      const newTraductions = { ...information };
      newTraductions[selectedLanguageTab].AxesOfAction.splice(index, 1);
      setInformation(newTraductions);
    }

    const handleChangeAxesOfActionInformation = (index, e) => {
    const updatedAxesOfAction = [...information[selectedLanguageTab].AxesOfAction];
    updatedAxesOfAction[index] = e.target.value;
    setInformation(prevState => ({
        ...prevState,
        [selectedLanguageTab]: {
            ...prevState[selectedLanguageTab],
            AxesOfAction: updatedAxesOfAction
        }
    }));
    }

    const renderEntityLanguage = (language) => {
        return (
          <div
                className={`tab-pane fade show ${
                    selectedLanguageTab === language ? "active" : ""
                }`}
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Títol
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="Title"
                        value={
                          information[selectedLanguageTab].Title
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="textAreaExample">
                        Descripció
                      </label>
                      <textarea
                        className="form-control"
                        id="textAreaExample"
                        rows={4}
                        name="Description"
                        value={
                          information[selectedLanguageTab].Description
                        }
                        onChange={handleChangeInformation}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className='row mt-4 ml-1 mr-1'>
                  <button 
                      className="btn btn-sm btn-primary mt-2 float-right"
                      onClick={(e) => {
                          e.preventDefault();
                          addAxeOfAction();
                      }}
                  >
                      <i className="bi bi-plus-lg"></i><span style={{paddingLeft: '10px'}}>Afegir</span>
                  </button>
                  <div className="table-responsive">
                    <table className="table table-sm">
                        <thead className="table-primary">
                            <tr>
                                <th className="border-top-0 border-bottom-0" scope="col">Eixos d'actuació</th>
                                <th className="border-top-0 border-bottom-0 text-center" scope="col">Posició</th>
                                <th className="border-top-0 border-bottom-0"></th>
                                <th className="border-top-0 border-bottom-0"></th>
                                <th className="border-top-0 border-bottom-0"></th>
                            </tr>
                        </thead>
                            <tbody>
                              {information[selectedLanguageTab].AxesOfAction.map((point, index) => {
                                return (
                                  <tr key={index} className="animated fadeIn">
                                    <textarea
                                      className="form-control"
                                      id="textAreaExample"
                                      rows={2}
                                      name="AxesOfAction"
                                      value={
                                        point
                                      }
                                      onChange={(e) => handleChangeAxesOfActionInformation(index,e)}
                                    ></textarea>
                                    <td className="text-center">{`${index + 1}`}</td>
                                    <td>
                                        <button className="btn btn-sm btn-primary"
                                                disabled={index === 0}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    changeAxesOfActionPosition(index, 'Up')
                                                }}>
                                            <i class="bi bi-arrow-up"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button className="btn btn-sm btn-primary"
                                                disabled={(index+1)===information[selectedLanguageTab].AxesOfAction.length}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    changeAxesOfActionPosition(index, 'Down')
                                                }}>
                                            <i class="bi bi-arrow-down"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button className="btn btn-sm btn-danger"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    removeAxeOfAction(index)
                                                }}>
                                            <i class="bi bi-trash3"></i>
                                        </button>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                      </table>
                  </div>
                </div>
              </div>
        )
    }

    return (
        <div className="card m-4">
        <div className="card-header d-flex justify-content-between align-items-center py-1">
          <span className="me-auto font-weight-bold">ENTITAT</span>
          <button type="button" onClick={() => props.handleSaveTraductions(information)} className="btn btn-success btn-rounded btn-sm">
          <i className="bi bi-save"></i> <span style={{paddingLeft: '10px'}}>Guardar</span>
          </button>
        </div>
        <div className="card-body">
          <div>
            <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'Catalan' ? "active" : ""
                  }`}
                  id="ex1-tab-1"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-1"
                  aria-selected={selectedLanguageTab === 'Catalan'}
                  onClick={(event) => handleNetworkTabChange('Catalan')}
                >
                  Català
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'Spanish' ? "active" : ""
                  }`}
                  id="ex1-tab-4"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-4"
                  aria-selected={selectedLanguageTab === 'Spanish'}
                  onClick={(event) => handleNetworkTabChange('Spanish')}
                >
                  Castellà
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'English' ? "active" : ""
                  }`}
                  id="ex1-tab-2"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-2"
                  aria-selected={selectedLanguageTab === 'English'}
                  onClick={(event) => handleNetworkTabChange('English')}
                >
                  Anglès
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'French' ? "active" : ""
                  }`}
                  id="ex1-tab-3"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-3"
                  aria-selected={selectedLanguageTab === 'French'}
                  onClick={(event) => handleNetworkTabChange('French')}
                >
                  Francès
                </button>
              </li>
            </ul>
            <div className="tab-content" id="ex1-content">
              {renderEntityLanguage('Catalan')}
              {renderEntityLanguage('Spanish')}
              {renderEntityLanguage('English')}
              {renderEntityLanguage('French')}
            </div>
          </div>
        </div>
      </div>
    )
}

export default EntityPanelAdmin