import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  setSelectedMenu,
  setSelectedSubmenu
} from '../../../actions'
import ApiService from '../../../services/ApiService'
import FormationPanelAdmin from './FormationPanelAdmin'
import Projectes from './FormationProjectAdmin'


const FormationAdministrator = (props) => {

    const { formation } = props

    const [temporalFormationPage, setTemporalFormationPage] = useState(formation);
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState({ title: "", message: "", color: "" });

    useEffect(() => {
        window.scrollTo(0, 0)
        if(props.selectedMenu !== 'Admin') props.dispatchSetSelectedMenu('Admin')
        props.dispatchSetSelectedSubmenu('FormationAdministrator')
    },[])
    
    const handleSaveTitleAndSubtitle = async (newTraductions) => {
        const newHomePage = Object.assign({}, temporalFormationPage);
        newHomePage.Traductions = newTraductions;
        newHomePage.nameOfThePage = 'Formation';
        try {
          const response = await ApiService.updatePage(newHomePage);
          if (response) {
            console.log("Page updated successfully:", response);
            setToastContent({ title: "Success", message: "Page updated successfully.", color: "success" });
            setShowToast(true);
          } else {
            console.log("Failed to update the page.");
            setToastContent({ title: "Error", message: "Failed to update the page.", color: "danger" });
            setShowToast(true);
          }
        } catch (error) {
          console.error("Error updating the page:", error);
          setToastContent({ title: "Error", message: "Error updating the page.", color: "danger" });
          setShowToast(true);
        }
    }

    const handleSaveProjects = async (projects) => {
        const newFormationPage = Object.assign({}, temporalFormationPage);
        newFormationPage.Projects = projects;
        newFormationPage.nameOfThePage = 'Formation';
        try {
          const response = await ApiService.updatePage(newFormationPage);
          if (response) {
            console.log("Page updated successfully:", response);
            setToastContent({ title: "Success", message: "Page updated successfully.", color: "success" });
            setShowToast(true);
          } else {
            console.log("Failed to update the page.");
            setToastContent({ title: "Error", message: "Failed to update the page.", color: "danger" });
            setShowToast(true);
          }
        } catch (error) {
          console.error("Error updating the page:", error);
          setToastContent({ title: "Error", message: "Error updating the page.", color: "danger" });
          setShowToast(true);
        }
    };
    

    return (
        <>
            <FormationPanelAdmin traductions={formation.Traductions} handleSaveTitleAndSubtitle={handleSaveTitleAndSubtitle}/>
            <Projectes projects={formation.Projects} handleSaveProjects={handleSaveProjects} />
            <div className={`toast align-top bg-${toastContent.color} ${showToast ? 'show' : ''}`} role="alert" aria-live="assertive" aria-atomic="true" style={{position: 'fixed', top: '20px', right: '20px', zIndex: 1050}}>
                  <div className="toast-header">
                  <strong className="me-auto">{toastContent.title}</strong>
                  <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast(false)}></button>
                  </div>
                  <div className="toast-body">
                  {toastContent.message}
                  </div>
              </div>
        </>

    )
    
}

const mapDispatchToProps = dispatch => (
    {
        dispatchSetSelectedMenu: value => dispatch(setSelectedMenu(value)),
        dispatchSetSelectedSubmenu: value => dispatch(setSelectedSubmenu(value))
    }
)

const mapStateToProps = state => ({
    selectedMenu: state.selectedMenu,
    languageWeb: state.languageWeb,
    formation: state.pages.Formation
})

export default connect(mapStateToProps, mapDispatchToProps)(FormationAdministrator)