import React, { useState } from 'react'
import { isMobileSafari } from 'react-device-detect'
import { Link } from 'react-router-dom'
import MobileMenu from '../MobileMenu'
import convertDropboxLinkToImage from '../../../../utils/DropboxLinkConverter'
import './NavbarTopOfTheWindow.css'

const NavbarTopOfTheWindow = (props) => {

  const { corporateIdentity, handleActiveLink, handleCalculateNewRoute, handleChangeLanguage, information, languageWeb, routes, selectedMenu, selectedSubmenu, traductions } = props

  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false)
  const [typeOfMobile] = useState('NavbarTopOfTheWindow')

  const handleShowMobileMenu = () => {
    setIsShowMobileMenu(!isShowMobileMenu)
  }

  const handleActiveLinkMobile = () => {
    handleShowMobileMenu()
  }
  
  const renderDesktopVersion = () => {
    return (
      <div className="row Navbar_TopOfTheWindow_Style Navbar_TopOfTheWindow_Desktop_View fixed-top">
        <div className="col-2 Navbar_TopOfTheWindow_Identity_Padding">
          <Link to={`/`}
                onClick={handleActiveLink}
                name={"Home"}>
            <img src={convertDropboxLinkToImage(corporateIdentity)} height='70' alt='Corporative identity ConArte Internacional'/>
          </Link>
        </div>
        <div className="col-8 Navbar_TopOfTheWindow_Menu_JustifyContent">
          <ul className='Navbar_TopOfTheWindow_List_Style'>
            <li>
              <Link className={`nav-link effect Navbar_TopOfTheWindow_Link_Style ${selectedMenu === 'Entity' ? 'Navbar_TopOfTheWindow_Link_Bullet' : 'Navbar_TopOfTheWindow_Link_Bullet_Hover'}`}
                    to={routes.Traductions[languageWeb].Entity}
                    onClick={handleActiveLink}
                    disabled={true}
                    name={"Entity"}>{traductions[languageWeb].EntityMenu}</Link>
            </li>
            <li>
              <Link className={`nav-link effect Navbar_TopOfTheWindow_Link_Style ${selectedMenu === 'Planters' ? 'Navbar_TopOfTheWindow_Link_Bullet' : 'Navbar_TopOfTheWindow_Link_Bullet_Hover'}`}
                    to={routes.Traductions[languageWeb].Planters}
                    onClick={handleActiveLink}
                    disabled={true}
                    name={"Planters"}>{traductions[languageWeb].PlantersMenu}</Link>
            </li>
            <li>
              <Link className={`nav-link effect Navbar_TopOfTheWindow_Link_Style ${selectedMenu === 'Network' ? 'Navbar_TopOfTheWindow_Link_Bullet' : 'Navbar_TopOfTheWindow_Link_Bullet_Hover'}`}
                    to={routes.Traductions[languageWeb].Network}
                    onClick={handleActiveLink}
                    disabled={true}
                    name={"Network"}>{traductions[languageWeb].NetworkMenu}</Link>
            </li>
            <li>
              <Link className={`nav-link effect Navbar_TopOfTheWindow_Link_Style ${selectedMenu === 'Supports' ? 'Navbar_TopOfTheWindow_Link_Bullet' : 'Navbar_TopOfTheWindow_Link_Bullet_Hover'}`}
                    to={routes.Traductions[languageWeb].Supports}
                    onClick={handleActiveLink}
                    disabled={true}
                    name={"Supports"}>{traductions[languageWeb].SupportsMenu}</Link>
            </li>
          </ul>
        </div>
        <div className="col-2">
          <ul className={"Navbar_TopOfTheWindow_List_Style Navbar_TopOfTheWindow_UL_TextAlign"}>
            <li className="nav-item">
              <Link className={`nav-link effect Navbar_TopOfTheWindow_LanguageText pr-0 ${languageWeb === 'Catalan' ? 'Navbar_TopOfTheWindow_Language_Selected' : '' }`}
                    to={handleCalculateNewRoute('Catalan')}
                    onClick={handleChangeLanguage}
                    disabled={true}
                    name={"Catalan"}>{traductions[languageWeb].CatalanLanguage}</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link effect Navbar_TopOfTheWindow_LanguageText pr-0 ${languageWeb === 'Spanish' ? 'Navbar_TopOfTheWindow_Language_Selected' : '' }`}
                    to={handleCalculateNewRoute('Spanish')}
                    onClick={handleChangeLanguage}
                    disabled={true}
                    name={"Spanish"}>{traductions[languageWeb].SpanishLanguage}</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link effect Navbar_TopOfTheWindow_LanguageText pr-0 ${languageWeb === 'English' ? 'Navbar_TopOfTheWindow_Language_Selected' : '' }`}
                    to={handleCalculateNewRoute('English')}
                    onClick={handleChangeLanguage}
                    disabled={true}
                    name={"English"}>{traductions[languageWeb].EnglishLanguage}</Link>
            </li>
          </ul>
        </div>
      </div>
    )
  }

  const renderTabletVersion = () => {
    return (
      <>
        <div className="row Navbar_TopOfTheWindow_Mobile_Style Navbar_TopOfTheWindow_Tablet_View fixed-top">
          <div className="col-6 Navbar_TopOfTheWindow_Mobile_Identity_Padding">
            {
              !isShowMobileMenu &&
              <Link to={`/`}
                    onClick={handleActiveLink}
                    name={"Home"}>
                <img src={convertDropboxLinkToImage(corporateIdentity)} height='70' alt='Corporative identity ConArte Internacional'/>
              </Link>
            }
          </div>
          <div className="col-6 Navbar_TopOfTheWindow_Mobile_Menu_Padding">
            <button className={`${isShowMobileMenu || isMobileSafari ? 'Navbar_TopOfTheWindow_Mobile_Button_Without_Circle_Style': 'Navbar_TopOfTheWindow_Mobile_Button_Circle_Style'}`} style={{float: 'right'}} onClick={() => handleShowMobileMenu()}>
              {!isShowMobileMenu && <i className="bi bi-list Navbar_TopOfTheWindow_Icon"></i>}
              {isShowMobileMenu && <i className="bi bi-x-lg Navbar_TopOfTheWindow_Icon"></i>}
            </button>
          </div>
        </div>
        {
          isShowMobileMenu &&
          <MobileMenu
            handleActiveLink={handleActiveLinkMobile}
            handleCalculateNewRoute={handleCalculateNewRoute}
            handleChangeLanguage={handleChangeLanguage}
            information={information}
            languageWeb={languageWeb}
            routes={routes}
            selectedMenu={selectedMenu}
            selectedSubmenu={selectedSubmenu}
            traductions={traductions}
            typeOfMobile={typeOfMobile} />
        }
      </>
    )
  }

  const renderMobileVersion = () => {
    return (
      <>
        <div className="row Navbar_TopOfTheWindow_Mobile_Style Navbar_TopOfTheWindow_Mobile_View fixed-top">
          <div className="col-6 Navbar_TopOfTheWindow_Mobile_Identity_Padding">
            {
              !isShowMobileMenu && 
              <Link to={`/`}
                    onClick={handleActiveLink}
                    name={"Home"}>
                <img src={convertDropboxLinkToImage(corporateIdentity)} height='70' alt='Corporative identity ConArte Internacional'/>
              </Link>
            }
          </div>
          <div className="col-6 Navbar_TopOfTheWindow_Mobile_Menu_Padding">
            <button className={`${isShowMobileMenu || isMobileSafari ? 'Navbar_TopOfTheWindow_Mobile_Button_Without_Circle_Style': 'Navbar_TopOfTheWindow_Mobile_Button_Circle_Style'}`} style={{float: 'right'}} onClick={() => handleShowMobileMenu()}>
              {!isShowMobileMenu && <i className="bi bi-list Navbar_TopOfTheWindow_Icon"></i>}
              {isShowMobileMenu && <i className="bi bi-x-lg Navbar_TopOfTheWindow_Icon"></i>}
            </button>
          </div>
        </div>
        {
          isShowMobileMenu &&
          <MobileMenu 
            handleActiveLink={handleActiveLinkMobile}
            handleCalculateNewRoute={handleCalculateNewRoute}
            handleChangeLanguage={handleChangeLanguage} 
            information={information}
            languageWeb={languageWeb} 
            routes={routes}
            selectedMenu={selectedMenu}
            selectedSubmenu={selectedSubmenu}
            traductions={traductions}
            typeOfMobile={typeOfMobile} />
        }
      </>
    )
  }

  return (
    <>
      {renderDesktopVersion()}
      {renderTabletVersion()}
      {renderMobileVersion()}
    </>
  )

}

export default NavbarTopOfTheWindow