import React, { useState } from 'react'

const SupportPanelAdmin = (props) => {

    const { traductions } = props

    const [selectedLanguageTab, setSelectedLanguageTab] = useState(
        'Catalan'
    )

    const [temporalTraductions, setTemporalTraductions] = useState(traductions);

    const handleNetworkTabChange = (language) => {
        setSelectedLanguageTab(language);
    }

    const handleChangeInformation = (event) => {
      const { name, value } = event.target
      setTemporalTraductions((prevInformation) => ({
      ...prevInformation,
      [selectedLanguageTab]: {
          ...prevInformation[selectedLanguageTab],
          [name]: value,
      },
      }))
    }

    const renderLanguageSection = (language) => {
        return (
            <div
                className={`tab-pane fade show ${
                    selectedLanguageTab === language ? "active" : ""
                }`}
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Títol 1
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="Subtitle"
                        value={
                          temporalTraductions[selectedLanguageTab].Subtitle
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Títol 2
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="SecondSubtitle"
                        value={
                          temporalTraductions[selectedLanguageTab].SecondSubtitle
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">Teaming</label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="TeamerTitle"
                        value={
                          temporalTraductions[selectedLanguageTab].TeamerTitle
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="form-outline">
                        <label className="form-label" htmlFor="form12">
                            Text
                        </label>
                        <textarea
                            className="form-control"
                            id="textAreaExample"
                            rows={2}
                            name="Description"
                            value={
                                temporalTraductions[selectedLanguageTab].Description
                            }
                            onChange={handleChangeInformation}
                        ></textarea>
                      </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Títol botó
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ButtonText"
                        value={
                          temporalTraductions[selectedLanguageTab].ButtonText
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Enllaç botó
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ButtonText"
                        value={
                          temporalTraductions[selectedLanguageTab].ButtonLink
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                </div>
            </div>
        )
    }

    const handleSaveTraductions = () => {
        props.handleSaveTraductions(temporalTraductions)
    }

    return (
        <div className="card m-4">
        <div className="card-header d-flex justify-content-between align-items-center py-1">
          <span className="me-auto font-weight-bold">SUPORTS</span>
          <button type="button" onClick={handleSaveTraductions} className="btn btn-success btn-rounded btn-sm">
          <i className="bi bi-save"></i> <span style={{paddingLeft: '10px'}}>Guardar</span>
          </button>
        </div>
        <div className="card-body">
          <div>
            <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'Catalan' ? "active" : ""
                  }`}
                  id="ex1-tab-1"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-1"
                  aria-selected={selectedLanguageTab === 'Catalan'}
                  onClick={() => handleNetworkTabChange('Catalan')}
                >
                  Català
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'Spanish' ? "active" : ""
                  }`}
                  id="ex1-tab-4"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-4"
                  aria-selected={selectedLanguageTab === 'Spanish'}
                  onClick={() => handleNetworkTabChange('Spanish')}
                >
                  Castellà
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'English' ? "active" : ""
                  }`}
                  id="ex1-tab-2"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-2"
                  aria-selected={selectedLanguageTab === 'English'}
                  onClick={() => handleNetworkTabChange('English')}
                >
                  Anglès
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'French' ? "active" : ""
                  }`}
                  id="ex1-tab-3"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-3"
                  aria-selected={selectedLanguageTab === 'French'}
                  onClick={() => handleNetworkTabChange('French')}
                >
                  Francès
                </button>
              </li>
            </ul>
            {/* Tabs content */}
            <div className="tab-content" id="ex1-content">
              {renderLanguageSection('Catalan')}
              {renderLanguageSection('Spanish')}
              {renderLanguageSection('English')}
              {renderLanguageSection('French')}
            </div>
          </div>
        </div>
      </div>
    )
}

export default SupportPanelAdmin