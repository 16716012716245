import React from 'react'
import { connect } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'
import {
  setLanguage,
  setSelectedMenu,
  setIsTopOnTheWindow,
  setSelectedSubmenu,
  setIsAnimation
} from '../../../actions'
import NavbarTopOfTheWindow from './NavbarTopOfTheWindow'
import Navbar from './Navbar'
import NavigationBarAdmin from './NavigationBarAdmin'


const NavbarResponsive = (props) => {

  const { isAnimation, isTopOnTheWindow, languageWeb, menu, routes, selectedSubmenu, selectedMenu } = props
  const { dispatchSetIsAnimation, dispatchSetLanguageWeb, dispatchSetSelectedMenu, dispatchSetSelectedSubmenu} = props

  window.onscroll = function() {
    let currentScrollPos = window.pageYOffset;
    if(currentScrollPos === 0){
      props.dispatchSetIsTopOnTheWindow(true)
    } else {
      props.dispatchSetIsTopOnTheWindow(false)
    }
  }

  const handleChangeLanguage = (event) => {
    dispatchSetLanguageWeb(event.target.name)
  }

  const handleActiveLink = event => {
    dispatchSetSelectedMenu(event.target.name === undefined ? 'Home': event.target.name)
    if(event.target.name === 'Planters') dispatchSetSelectedSubmenu('')
  }

  const handleActiveSubmenu = event => {
    dispatchSetSelectedSubmenu(event.target.name)
  }

  const handleCalculateNewRoute = (newLanguage) => {
    if (isMobileOnly) {
      if (selectedMenu === 'Arbrar') return routes.Traductions[languageWeb][selectedMenu]
      if (selectedSubmenu === 'Schools') return routes.Traductions[newLanguage].SchoolsMobile
      if (selectedSubmenu === 'Extracurricular activities') return routes.Traductions[newLanguage].ExtracurricularActivitiesMobile
      if (selectedSubmenu === 'Formation') return routes.Traductions[newLanguage].FormationMobile
      if (selectedSubmenu === 'Productions') return routes.Traductions[newLanguage].ProductionsMobile
      return routes.Traductions[newLanguage].PlantersMobile
    } else {
      return routes.Traductions[newLanguage][selectedMenu]
    }
  }

  const handleActiveOrDeactiveAnimation = () => {
    dispatchSetIsAnimation(!isAnimation)
  }

  const renderNavbarComponent = () => {
    if (isTopOnTheWindow  && selectedMenu === 'Home') {
      return (
          <NavbarTopOfTheWindow
              corporateIdentity={menu.Images.CorporativeIdentity}
              handleActiveLink={handleActiveLink}
              handleCalculateNewRoute={handleCalculateNewRoute}
              handleChangeLanguage={handleChangeLanguage}
              information={menu.Information}
              languageWeb={languageWeb}
              routes={routes}
              selectedMenu={selectedMenu}
              selectedSubmenu={selectedSubmenu}
              traductions={menu.Traductions}/>
      )
      } else if(selectedMenu === 'Arbrar') {
          // return (
          //   <NavbarArbrar
          //     handleActiveLink={handleActiveLink}
          //     handleActiveSubmenu={handleActiveSubmenu}
          //     handleCalculateNewRoute={handleCalculateNewRoute}
          //     handleChangeLanguage={handleChangeLanguage}
          //     handleActiveOrDeactiveAnimation={handleActiveOrDeactiveAnimation}
          //     languageWeb={languageWeb}
          //     routes={routes}
          //     selectedSubmenu={selectedSubmenu}
          //     selectedMenu={selectedMenu}
          //     traductions={menu.Traductions}/>
          // )
          return
      } else if(selectedMenu === 'Admin'){
            if (selectedSubmenu !== '') {
                return (
                  <NavigationBarAdmin />
                )
            } else {
              return null
            }

      } else {
        return (
            <Navbar
              handleActiveLink={handleActiveLink}
              handleActiveSubmenu={handleActiveSubmenu}
              handleCalculateNewRoute={handleCalculateNewRoute}
              handleChangeLanguage={handleChangeLanguage}
              information={menu.Information}
              languageWeb={languageWeb}
              routes={routes}
              selectedSubmenu={selectedSubmenu}
              selectedMenu={selectedMenu}
              traductions={menu.Traductions}/>
          
        )
    }
  }

  return (
    <>
      {renderNavbarComponent()}
    </>
  )
}

const mapStateToProps = state => ({
  selectedMenu: state.selectedMenu,
  selectedSubmenu: state.selectedSubmenu,
  languageWeb: state.languageWeb,
  menu: state.resources.Menu,
  routes: state.resources.Routes,
  isTopOnTheWindow: state.isTopOnTheWindow,
  isAnimation: state.isAnimation
})

const mapDispatchToProps = dispatch => (
  {
    dispatchSetSelectedMenu: value => dispatch(setSelectedMenu(value)),
    dispatchSetLanguageWeb: value => dispatch(setLanguage(value)),
    dispatchSetSelectedSubmenu: value => dispatch(setSelectedSubmenu(value)),
    dispatchSetIsTopOnTheWindow: value => dispatch(setIsTopOnTheWindow(value)),
    dispatchSetIsAnimation: value => dispatch(setIsAnimation(value))
  }
)

export default connect(mapStateToProps, mapDispatchToProps)(NavbarResponsive)