import {
    SET_PAGES,
    SET_LOGO_AND_IDENTITY,
    SET_LANGUAGE,
    SET_MENU,
    SET_FOOTER,
    SET_ROUTES,
    SET_SELECTED_MENU,
    SET_SELECTED_SUBMENU,
    SET_SELECTED_ROUTE,
    SET_IS_TOP_ON_THE_WINDOW, 
    SET_IS_ANIMATION,
    SET_ARBRAR_POSITION
} from '../actions'

export const informationReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_MENU:
            return {
                  ...state,
                  resources: {
                    ...state.resources,
                    Menu: action.payload
                  }
            }
            case SET_FOOTER:
            return {
                  ...state,
                  resources: {
                    ...state.resources,
                    Footer: action.payload
                  }
            }
            case SET_ROUTES:
            return {
                  ...state,
                  resources: {
                    ...state.resources,
                    Routes: action.payload
                  }
            }
        case SET_PAGES:
            return {...state, pages: action.payload}
        case SET_LOGO_AND_IDENTITY:
            return {...state, logoAndIdentity: action.payload}
        case SET_LANGUAGE:
            return {...state, languageWeb: action.payload}
        case SET_SELECTED_ROUTE:
            return {...state, selectedRoute: action.payload}
        case SET_SELECTED_MENU:
            return {...state, selectedMenu: action.payload}
        case SET_SELECTED_SUBMENU:
            return {...state, selectedSubmenu: action.payload}
        case SET_IS_TOP_ON_THE_WINDOW:
            return {...state, isTopOnTheWindow: action.payload}
        case SET_IS_ANIMATION:
            return {...state, isAnimation: action.payload}
        case SET_ARBRAR_POSITION:
            return {...state, arbrarPosition: action.payload}
        default:
            return state
    }
}

