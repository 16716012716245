import React, { useState } from 'react'

const WorkingLinesPanelAdmin = (props) => {

    const { traductions } = props

    const [selectedLanguageTab, setSelectedLanguageTab] = useState(
        'Catalan'
    )

    const [temporalTraductions, setTemporalTraductions] = useState(traductions);

    const handleSelectedLanguageTabChange = (language) => {
        setSelectedLanguageTab(language);
    }

    const renderLanguageSection = (language) => {
        return (
            <div
                className={`tab-pane fade show ${
                    selectedLanguageTab === language ? "active" : ""
                }`}
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Títol
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="WorklinesTitle"
                        value={
                          temporalTraductions[selectedLanguageTab].WorklinesTitle
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        1
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="FirstWorklineTitle"
                        value={
                          temporalTraductions[selectedLanguageTab].FirstWorklineTitle
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        2
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="SecondWorklineTitle"
                        value={
                          temporalTraductions[selectedLanguageTab].SecondWorklineTitle
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-outline">
                      <textarea
                        className="form-control"
                        id="textAreaExample"
                        rows={5}
                        name="FirstWorklineDescription"
                        value={
                          temporalTraductions[selectedLanguageTab].FirstWorklineDescription
                        }
                        onChange={handleChangeInformation}
                      ></textarea>           
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-outline">
                      <textarea
                          className="form-control"
                          id="textAreaExample"
                          rows={5}
                          name="SecondWorklineDescription"
                          value={
                            temporalTraductions[selectedLanguageTab].SecondWorklineDescription
                          }
                          onChange={handleChangeInformation}
                        ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        3
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ThirdWorklineTitle"
                        value={
                          temporalTraductions[selectedLanguageTab].ThirdWorklineTitle
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        4
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="FourthWorklineTitle"
                        value={
                          temporalTraductions[selectedLanguageTab].FourthWorklineTitle
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-outline">
                      <textarea
                          className="form-control"
                          id="textAreaExample"
                          rows={5}
                          name="ThirdWorklineDescription"
                          value={
                            temporalTraductions[selectedLanguageTab].ThirdWorklineDescription
                          }
                          onChange={handleChangeInformation}
                        ></textarea>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-outline">
                      <textarea
                          className="form-control"
                          id="textAreaExample"
                          rows={5}
                          name="FourthWorklineDescription"
                          value={
                            temporalTraductions[selectedLanguageTab].FourthWorklineDescription
                          }
                          onChange={handleChangeInformation}
                        ></textarea>
                    </div>
                  </div>
                </div>
                </div>
        )
    }
  
    const handleChangeInformation = (event) => {
      const { name, value } = event.target;  // Obtenemos el nombre y el valor del campo que se ha cambiado
  
      // Actualizamos la traducción correspondiente en temporalTraductions
      setTemporalTraductions(prevTraductions => {
          return {
              ...prevTraductions,
              [selectedLanguageTab]: {
                  ...prevTraductions[selectedLanguageTab],
                  [name]: value
              }
          };
      });
    }
  
    const handleSaveWorkingLines = () => {
        props.handleSavePlanters(temporalTraductions)
    }

    return (
        <div className="card m-4">
        <div className="card-header d-flex justify-content-between align-items-center py-1">
          <span className="me-auto">LÍNIES DE TREBALL</span>
          <button type="button" onClick={handleSaveWorkingLines} className="btn btn-success btn-rounded btn-sm">
          <i className="bi bi-save"></i> <span style={{paddingLeft: '10px'}}>Guardar</span>
          </button>
        </div>
        <div className="card-body">
          <div>
            <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'Catalan' ? "active" : ""
                  }`}
                  id="ex1-tab-1"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-1"
                  aria-selected={selectedLanguageTab === 'Catalan'}
                  onClick={(event) => handleSelectedLanguageTabChange('Catalan')}
                >
                  Català
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'Spanish' ? "active" : ""
                  }`}
                  id="ex1-tab-4"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-4"
                  aria-selected={selectedLanguageTab === 'Spanish'}
                  onClick={(event) => handleSelectedLanguageTabChange('Spanish')}
                >
                  Castellà
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'English' ? "active" : ""
                  }`}
                  id="ex1-tab-2"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-2"
                  aria-selected={selectedLanguageTab === 'English'}
                  onClick={(event) => handleSelectedLanguageTabChange('English')}
                >
                  Anglès
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'French' ? "active" : ""
                  }`}
                  id="ex1-tab-3"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-3"
                  aria-selected={selectedLanguageTab === 'French'}
                  onClick={(event) => handleSelectedLanguageTabChange('French')}
                >
                  Francès
                </button>
              </li>
            </ul>
            <div className="tab-content" id="ex1-content">
              {renderLanguageSection('Catalan')}
              {renderLanguageSection('Spanish')}
              {renderLanguageSection('English')}
              {renderLanguageSection('French')}
            </div>
          </div>
        </div>
      </div>
    )
}

export default WorkingLinesPanelAdmin