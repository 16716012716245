import React, { useState } from 'react'

const MenuPanelAdmin = (props) => {

    const { menu } = props

    const [selectedLanguageTab, setSelectedLanguageTab] = useState(
        'Catalan'
    )

    const [temporalMenu, setTemporalMenu] = useState(menu);

    const handleNetworkTabChange = (language) => {
        setSelectedLanguageTab(language);
    }

    const renderLanguageSection = (language) => {
        return (
            <div
                className={`tab-pane fade show ${
                    selectedLanguageTab === language ? "active" : ""
                }`}
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Menús
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="Carousel"
                        value={
                          temporalMenu.Traductions[selectedLanguageTab].EntityMenu
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                  <div className="col-md-3 pt-2">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                       
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="PlantersMenu"
                        value={
                          temporalMenu.Traductions[selectedLanguageTab].PlantersMenu
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                  <div className="col-md-3 pt-2">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="NetworkMenu"
                        value={
                          temporalMenu.Traductions[selectedLanguageTab].NetworkMenu
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                  <div className="col-md-3 pt-2">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="SupportsMenu"
                        value={
                          temporalMenu.Traductions[selectedLanguageTab].SupportsMenu
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Submenús
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="SchoolsMenu"
                        value={
                          temporalMenu.Traductions[selectedLanguageTab].SchoolsMenu
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                  <div className="col-md-3 pt-2">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ExtracurricularActivitiesMenu"
                        value={
                          temporalMenu.Traductions[selectedLanguageTab].ExtracurricularActivitiesMenu
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                  <div className="col-md-3 pt-2">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="FormationMenu"
                        value={
                          temporalMenu.Traductions[selectedLanguageTab].FormationMenu
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                  <div className="col-md-3 pt-2">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProductionsMenu"
                        value={
                          temporalMenu.Traductions[selectedLanguageTab].ProductionsMenu
                        }
                        onChange={handleChangeInformation}
                      />
                      </div>
                  </div>
                </div>
              </div>
        )
    }

    const renderLanguageSectionLng = (language) => {
      return (
          <div
              className={`tab-pane fade show ${
                  selectedLanguageTab === language ? "active" : ""
              }`}
              id="ex1-tabs-1"
              role="tabpanel"
              aria-labelledby="ex1-tab-1"
            >

              <div className="row mt-2">
                <div className="col-md-3">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form12">
                      Català
                    </label>
                    <input
                      type="text"
                      id="form12"
                      className="form-control"
                      name="CatalanLanguage"
                      value={
                        temporalMenu.Traductions[selectedLanguageTab].CatalanLanguage
                      }
                      onChange={handleChangeInformation}
                    />
                    </div>
                </div>
                <div className="col-md-3">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form12">
                      Castellà 
                    </label>
                    <input
                      type="text"
                      id="form12"
                      className="form-control"
                      name="SpanishLanguage"
                      value={
                        temporalMenu.Traductions[selectedLanguageTab].SpanishLanguage
                      }
                      onChange={handleChangeInformation}
                    />
                    </div>
                </div>
                <div className="col-md-3">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form12">
                      Anglès 
                    </label>
                    <input
                      type="text"
                      id="form12"
                      className="form-control"
                      name="EnglishLanguage"
                      value={
                        temporalMenu.Traductions[selectedLanguageTab].EnglishLanguage
                      }
                      onChange={handleChangeInformation}
                    />
                    </div>
                </div>
                <div className="col-md-3">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form12">
                      Francès
                    </label>
                    <input
                      type="text"
                      id="form12"
                      className="form-control"
                      name="FrenchLanguage"
                      value={
                        temporalMenu.Traductions[selectedLanguageTab].FrenchLanguage
                      }
                      onChange={handleChangeInformation}
                    />
                    </div>
                </div>
              </div>


              </div>
      )
  }

    const handleSaveMenu = () => {
        props.handleSaveMenu()
    }

    const handleChangeLink = (event) => {
      const { value } = event.target;
  
      setTemporalMenu(prevMenu => ({
          ...prevMenu,
          Images: {
              ...prevMenu.Images,
              CorporativeIdentity: value
          }
      }));
    }
  
    const handleChangeEnterprise = (event) => {
      const { value } = event.target;
  
      setTemporalMenu(prevMenu => ({
          ...prevMenu,
          Information: {
              ...prevMenu.Information,
              CorporativeIdentity: value
          }
      }));
    }
  
    const handleChangeInformation = (event) => {
      const { name, value } = event.target;
  
      // Para campos que se encuentran dentro del objeto 'Traductions' basados en el idioma seleccionado
      if (temporalMenu.Traductions[selectedLanguageTab].hasOwnProperty(name)) {
          setTemporalMenu(prevMenu => ({
              ...prevMenu,
              Traductions: {
                  ...prevMenu.Traductions,
                  [selectedLanguageTab]: {
                      ...prevMenu.Traductions[selectedLanguageTab],
                      [name]: value
                  }
              }
          }));
      } 
    }
  

    return (
      <>
          <div className="card m-4">
            <div className="card-header d-flex justify-content-between align-items-center py-1">
              <span className="me-auto font-weight-bold">ALTRES CONTINGUTS</span>
              <button type="button" onClick={handleSaveMenu} className="btn btn-success btn-rounded btn-sm">
              <i className="bi bi-save"></i> <span style={{paddingLeft: '10px'}}>Guardar</span>
              </button>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-outline">
                    <label className="form-label font-weight-bold" htmlFor="form12">
                      Enllaç logo
                    </label>
                    <input
                      type="text"
                      id="form12"
                      className="form-control"
                      name="CorporativeIdentity"
                      value={
                        temporalMenu.Images.CorporativeIdentity
                      }
                      onChange={handleChangeLink}
                    />
                    </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <div className="form-outline">
                    <label className="form-label font-weight-bold" htmlFor="form12">
                      Nom de l'entitat
                    </label>
                    <input
                      type="text"
                      id="form12"
                      className="form-control"
                      name="CorporativeIdentity"
                      value={
                        temporalMenu.Information.CorporativeIdentity
                      }
                      onChange={handleChangeEnterprise}
                    />
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card m-4'>
            <div className="card-header d-flex justify-content-between align-items-center py-1">
              <span className="me-auto">BARRA DE NAVEGACIÓ</span>
              <button type="button" onClick={handleSaveMenu} className="btn btn-success btn-rounded btn-sm">
              <i className="bi bi-save"></i> <span style={{paddingLeft: '10px'}}>Guardar</span>
              </button>
            </div>
            <div className='card-body'>
                <div className='mt-3 ml-1'>
                  <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          selectedLanguageTab === 'Catalan' ? "active" : ""
                        }`}
                        id="ex1-tab-1"
                        data-mdb-toggle="tab"
                        role="tab"
                        aria-controls="ex1-tabs-1"
                        aria-selected={selectedLanguageTab === 'Catalan'}
                        onClick={(event) => handleNetworkTabChange('Catalan')}
                      >
                        Català
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          selectedLanguageTab === 'Spanish' ? "active" : ""
                        }`}
                        id="ex1-tab-4"
                        data-mdb-toggle="tab"
                        role="tab"
                        aria-controls="ex1-tabs-4"
                        aria-selected={selectedLanguageTab === 'Spanish'}
                        onClick={(event) => handleNetworkTabChange('Spanish')}
                      >
                        Castellà
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          selectedLanguageTab === 'English' ? "active" : ""
                        }`}
                        id="ex1-tab-2"
                        data-mdb-toggle="tab"
                        role="tab"
                        aria-controls="ex1-tabs-2"
                        aria-selected={selectedLanguageTab === 'English'}
                        onClick={(event) => handleNetworkTabChange('English')}
                      >
                        Anglès
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          selectedLanguageTab === 'French' ? "active" : ""
                        }`}
                        id="ex1-tab-3"
                        data-mdb-toggle="tab"
                        role="tab"
                        aria-controls="ex1-tabs-3"
                        aria-selected={selectedLanguageTab === 'French'}
                        onClick={(event) => handleNetworkTabChange('French')}
                      >
                        Francès
                      </button>
                    </li>
                  </ul>
                  {/* Tabs content */}
                  <div className="tab-content" id="ex1-content">
                    {renderLanguageSection('Catalan')}
                    {renderLanguageSection('Spanish')}
                    {renderLanguageSection('English')}
                    {renderLanguageSection('French')}
                  </div>
                </div>
            </div>
          </div>
          <div className='card m-4'>
            <div className="card-header d-flex justify-content-between align-items-center py-1">
              <span className="me-auto">IDIOMES</span>
              <button type="button" onClick={handleSaveMenu} className="btn btn-success btn-rounded btn-sm">
              <i className="bi bi-save"></i> <span style={{paddingLeft: '10px'}}>Guardar</span>
              </button>
            </div>
            <div className='card-body'>
                <div className='mt-3 ml-1'>
                  <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          selectedLanguageTab === 'Catalan' ? "active" : ""
                        }`}
                        id="ex1-tab-1"
                        data-mdb-toggle="tab"
                        role="tab"
                        aria-controls="ex1-tabs-1"
                        aria-selected={selectedLanguageTab === 'Catalan'}
                        onClick={(event) => handleNetworkTabChange('Catalan')}
                      >
                        Català
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          selectedLanguageTab === 'Spanish' ? "active" : ""
                        }`}
                        id="ex1-tab-4"
                        data-mdb-toggle="tab"
                        role="tab"
                        aria-controls="ex1-tabs-4"
                        aria-selected={selectedLanguageTab === 'Spanish'}
                        onClick={(event) => handleNetworkTabChange('Spanish')}
                      >
                        Castellà
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          selectedLanguageTab === 'English' ? "active" : ""
                        }`}
                        id="ex1-tab-2"
                        data-mdb-toggle="tab"
                        role="tab"
                        aria-controls="ex1-tabs-2"
                        aria-selected={selectedLanguageTab === 'English'}
                        onClick={(event) => handleNetworkTabChange('English')}
                      >
                        Anglès
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          selectedLanguageTab === 'French' ? "active" : ""
                        }`}
                        id="ex1-tab-3"
                        data-mdb-toggle="tab"
                        role="tab"
                        aria-controls="ex1-tabs-3"
                        aria-selected={selectedLanguageTab === 'French'}
                        onClick={(event) => handleNetworkTabChange('French')}
                      >
                        Francès
                      </button>
                    </li>
                  </ul>
                  {/* Tabs content */}
                  <div className="tab-content" id="ex1-content">
                    {renderLanguageSectionLng('Catalan')}
                    {renderLanguageSectionLng('Spanish')}
                    {renderLanguageSectionLng('English')}
                    {renderLanguageSectionLng('French')}
                  </div>
                </div>
            </div>
          </div>
      </>
    )
}

export default MenuPanelAdmin