import React, { useState } from 'react'

const WhoWeArePanelAdmin = (props) => {

    const { traductions, information } = props

    const [selectedLanguageTab, setSelectedLanguageTab] = useState(
        'Catalan'
    )

    const [temporalTraductions, setTemporalTraductions] = useState(traductions)
    const [temporalInformation, setTemporalInformation] = useState(information)

    const handleLanguageTabChange = (language) => {
      setSelectedLanguageTab(language);
    }

    const handleChangeInformation = (event) => {

      const { name, value } = event.target;

      setTemporalTraductions((prevInformation) => ({
      ...prevInformation,
      [selectedLanguageTab]: {
          ...prevInformation[selectedLanguageTab],
          [name]: value,
      },
      }));
  }

    const handleChangeTemporalInformation = (event) => {
      const { name, value } = event.target;
      setTemporalInformation((prevInformation) => ({
        ...prevInformation,
        [name]: value,
        }));
    }

    return (
        <div className="card m-4">
        <div className="card-header d-flex justify-content-between align-items-center py-1">
          <span className="me-auto">EQUIP</span>
          <button type="button" onClick={() => props.handleSaveTraductionsAndInformation(temporalTraductions, temporalInformation)} className="btn btn-success btn-rounded btn-sm">
          <i className="bi bi-save"></i> <span style={{paddingLeft: '10px'}}>Guardar</span>
          </button>
        </div>
        <div className="card-body">
          <div>
            <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'Catalan' ? "active" : ""
                  }`}
                  id="ex1-tab-1"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-1"
                  aria-selected={selectedLanguageTab === 'Catalan'}
                  onClick={(event) => handleLanguageTabChange('Catalan')}
                >
                  Català
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'Spanish' ? "active" : ""
                  }`}
                  id="ex1-tab-4"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-4"
                  aria-selected={selectedLanguageTab === 'Spanish'}
                  onClick={(event) => handleLanguageTabChange('Spanish')}
                >
                  Castellà
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'English' ? "active" : ""
                  }`}
                  id="ex1-tab-2"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-2"
                  aria-selected={selectedLanguageTab === 'English'}
                  onClick={(event) => handleLanguageTabChange('English')}
                >
                  Anglès
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedLanguageTab === 'French' ? "active" : ""
                  }`}
                  id="ex1-tab-3"
                  data-mdb-toggle="tab"
                  role="tab"
                  aria-controls="ex1-tabs-3"
                  aria-selected={selectedLanguageTab === 'French'}
                  onClick={(event) => handleLanguageTabChange('French')}
                >
                  Francès
                </button>
              </li>
              
            </ul>
            {/* Tabs content */}
            <div className="tab-content" id="ex1-content">
              <div
                className={`tab-pane fade show ${
                    selectedLanguageTab === 'Catalan' ? "active" : ""
                }`}
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
              
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="form12">
                          Títol
                        </label>
                        <input
                          type="text"
                          id="form12"
                          className="form-control"
                          name="WhoWeAre"
                          value={
                            temporalTraductions[selectedLanguageTab].WhoWeAre
                          }
                          onChange={handleChangeInformation}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="President"
                        value={
                          temporalInformation.President
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager1"
                        value={
                          temporalInformation.ProjectManager1
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager2"
                        value={
                          temporalInformation.ProjectManager2
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager3"
                        value={
                          temporalInformation.ProjectManager3
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                </div>

                <div className="row mt-2">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="President"
                        value={
                          temporalTraductions[selectedLanguageTab].President
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager1"
                        value={
                          temporalTraductions[selectedLanguageTab].ProjectManager1
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager2"
                        value={
                          temporalTraductions[selectedLanguageTab].ProjectManager2
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager3"
                        value={
                          temporalTraductions[selectedLanguageTab].ProjectManager3
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                </div>

                </div>
              <div
                className={`tab-pane fade show ${
                    selectedLanguageTab === 'Spanish' ? "active" : ""
                }`}
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
               
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="form12">
                          Títol
                        </label>
                        <input
                          type="text"
                          id="form12"
                          className="form-control"
                          name="WhoWeAre"
                          value={
                            temporalTraductions[selectedLanguageTab].WhoWeAre
                          }
                          onChange={handleChangeInformation}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="President"
                        value={
                          temporalInformation.President
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager1"
                        value={
                          temporalInformation.ProjectManager1
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager2"
                        value={
                          temporalInformation.ProjectManager2
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager3"
                        value={
                          temporalInformation.ProjectManager3
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                </div>

                <div className="row mt-2">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="President"
                        value={
                          temporalTraductions[selectedLanguageTab].President
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager1"
                        value={
                          temporalTraductions[selectedLanguageTab].ProjectManager1
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager2"
                        value={
                          temporalTraductions[selectedLanguageTab].ProjectManager2
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager3"
                        value={
                          temporalTraductions[selectedLanguageTab].ProjectManager3
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                </div>

              </div>
              <div
                className={`tab-pane fade show ${
                    selectedLanguageTab === 'English' ? "active" : ""
                }`}
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
           
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="form12">
                          Títol
                        </label>
                        <input
                          type="text"
                          id="form12"
                          className="form-control"
                          name="WhoWeAre"
                          value={
                            temporalTraductions[selectedLanguageTab].WhoWeAre
                          }
                          onChange={handleChangeInformation}
                        />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row mt-2">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="President"
                        value={
                          temporalInformation.President
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager1"
                        value={
                          temporalInformation.ProjectManager1
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager2"
                        value={
                          temporalInformation.ProjectManager2
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager3"
                        value={
                          temporalInformation.ProjectManager3
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                </div>

                <div className="row mt-2">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="President"
                        value={
                          temporalTraductions[selectedLanguageTab].President
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager1"
                        value={
                          temporalTraductions[selectedLanguageTab].ProjectManager1
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager2"
                        value={
                          temporalTraductions[selectedLanguageTab].ProjectManager2
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager3"
                        value={
                          temporalTraductions[selectedLanguageTab].ProjectManager3
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                </div>

              </div>
              <div
                className={`tab-pane fade show ${
                    selectedLanguageTab === 'French' ? "active" : ""
                }`}
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >

                <div className="row">
                    <div className="col-md-3">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="form12">
                          Títol
                        </label>
                        <input
                          type="text"
                          id="form12"
                          className="form-control"
                          name="WhoWeAre"
                          value={
                            temporalTraductions[selectedLanguageTab].WhoWeAre
                          }
                          onChange={handleChangeInformation}
                        />
                      </div>
                    </div>
                  </div>

                <div className="row mt-2">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="President"
                        value={
                          temporalInformation.President
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager1"
                        value={
                          temporalInformation.ProjectManager1
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager2"
                        value={
                          temporalInformation.ProjectManager2
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Nom
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager3"
                        value={
                          temporalInformation.ProjectManager3
                        }
                        onChange={handleChangeTemporalInformation}
                      />
                    </div>
                  </div>

                </div>

                <div className="row mt-2">
                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                        Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="President"
                        value={
                          temporalTraductions[selectedLanguageTab].President
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager1"
                        value={
                          temporalTraductions[selectedLanguageTab].ProjectManager1
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager2"
                        value={
                          temporalTraductions[selectedLanguageTab].ProjectManager2
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form12">
                      Càrrec
                      </label>
                      <input
                        type="text"
                        id="form12"
                        className="form-control"
                        name="ProjectManager3"
                        value={
                          temporalTraductions[selectedLanguageTab].ProjectManager3
                        }
                        onChange={handleChangeInformation}
                      />
                    </div>
                  </div>

                </div>

              </div>
            </div>
        
          </div>
        </div>
      </div>
    )
}

export default WhoWeArePanelAdmin