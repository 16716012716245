import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  setSelectedMenu,
  setSelectedSubmenu
} from '../../../actions'
import ApiService from '../../../services/ApiService'
import CarouselPanelAdmin from './partials/CarouselPanelAdmin'
import HomePanelAdmin from './partials/HomePanelAdmin'
import Projectes from './partials/ProjectesHomePanelAdmin'

const HomeAdministrator = (props) => {

    const { home } = props

    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState({ title: "", message: "", color: "" });

    useEffect(() => {
        window.scrollTo(0, 0)
        if(props.selectedMenu !== 'Admin') props.dispatchSetSelectedMenu('Admin')
        props.dispatchSetSelectedSubmenu('HomeAdministrator')
    },[])

    const handleSaveCarousel = async (newImages) => {
        const newPage = Object.assign({}, home);
        newPage.Images.Carousel = newImages;
        newPage.nameOfThePage = 'Home';
        try {
          const response = await ApiService.updatePage(newPage);
          if (response) {
            console.log("Page updated successfully:", response);
            setToastContent({ title: "Success", message: "Page updated successfully.", color: "success" });
            setShowToast(true);
          } else {
            console.log("Failed to update the page.");
            setToastContent({ title: "Error", message: "Failed to update the page.", color: "danger" });
            setShowToast(true);
          }
        } catch (error) {
          console.error("Error updating the page:", error);
          setToastContent({ title: "Error", message: "Error updating the page.", color: "danger" });
          setShowToast(true);
        }
    }
    
    const handleSaveTitleAndSubtitle = async (newTraductions) => {
        const newHomePage = Object.assign({}, home);
        newHomePage.Traductions = newTraductions;
        newHomePage.nameOfThePage = 'Home';
        try {
          const response = await ApiService.updatePage(newHomePage);
          if (response) {
            console.log("Page updated successfully:", response);
            setToastContent({ title: "Success", message: "Page updated successfully.", color: "success" });
            setShowToast(true);
          } else {
            console.log("Failed to update the page.");
            setToastContent({ title: "Error", message: "Failed to update the page.", color: "danger" });
            setShowToast(true);
          }
        } catch (error) {
          console.error("Error updating the page:", error);
          setToastContent({ title: "Error", message: "Error updating the page.", color: "danger" });
          setShowToast(true);
        }
    }

    const handleSaveProjects = async (projects) => {
        const newHomePage = Object.assign({}, home);
        newHomePage.Projects = projects;
        newHomePage.nameOfThePage = 'Home';
        try {
          const response = await ApiService.updatePage(newHomePage);
          if (response) {
            console.log("Page updated successfully:", response);
            setToastContent({ title: "Success", message: "Page updated successfully.", color: "success" });
            setShowToast(true);
          } else {
            console.log("Failed to update the page.");
            setToastContent({ title: "Error", message: "Failed to update the page.", color: "danger" });
            setShowToast(true);
          }
        } catch (error) {
          console.error("Error updating the page:", error);
          setToastContent({ title: "Error", message: "Error updating the page.", color: "danger" });
          setShowToast(true);
        }
    }
    
    return (
        <>
           <CarouselPanelAdmin images={home.Images.Carousel} traductions={home.Traductions} handleSaveCarousel={handleSaveCarousel} />
           <HomePanelAdmin traductions={home.Traductions} handleSaveTitleAndSubtitle={handleSaveTitleAndSubtitle}/>
           <Projectes traductions={home.Traductions} projects={home.Projects} handleSaveProjects={handleSaveProjects} />
           <div className={`toast align-top bg-${toastContent.color} ${showToast ? 'show' : ''}`} role="alert" aria-live="assertive" aria-atomic="true" style={{position: 'fixed', top: '20px', right: '20px', zIndex: 1050}}>
                <div className="toast-header">
                <strong className="me-auto">{toastContent.title}</strong>
                <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast(false)}></button>
                </div>
                <div className="toast-body">
                {toastContent.message}
                </div>
            </div>
        </>

    )
    
}

const mapDispatchToProps = dispatch => (
    {
        dispatchSetSelectedMenu: value => dispatch(setSelectedMenu(value)),
        dispatchSetSelectedSubmenu: value => dispatch(setSelectedSubmenu(value))
    }
)

const mapStateToProps = state => ({
    selectedMenu: state.selectedMenu,
    languageWeb: state.languageWeb,
    home: state.pages.Home
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeAdministrator)


// const EntityAdministrator = (props) => {

//     const { entity } = props

//     const [temporalEntityPage, setTemporalEntityPage] = useState(entity);
//     const [showToast, setShowToast] = useState(false);
//     const [toastContent, setToastContent] = useState({ title: "", message: "", color: "" });

//     useEffect(() => {
//         window.scrollTo(0, 0)
//         if(props.selectedMenu !== 'Admin') props.dispatchSetSelectedMenu('Admin')
//         props.dispatchSetSelectedSubmenu('EntityAdministrator')
//     },[])

//     const handleSaveEntityPanelAdmin = () => {
      
//     }

//     return (
//         <>
//           <EntityPanelAdmin traductions={entity.Traductions} handleSaveEntityPanelAdmin={handleSaveEntityPanelAdmin} />
//           <WhoWeArePanelAdmin traductions={entity.Traductions} information={entity.Information} handleSaveEntityPanelAdmin={handleSaveEntityPanelAdmin} />
//           <InspiradoresPanelAdmin inspiring={entity.Information.Inspiring} traductions={entity.Traductions} handleSaveEntityPanelAdmin={handleSaveEntityPanelAdmin} />
//            <div className={`toast align-top bg-${toastContent.color} ${showToast ? 'show' : ''}`} role="alert" aria-live="assertive" aria-atomic="true" style={{position: 'fixed', top: '20px', right: '20px', zIndex: 1050}}>
//                 <div className="toast-header">
//                 <strong className="me-auto">{toastContent.title}</strong>
//                 <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast(false)}></button>
//                 </div>
//                 <div className="toast-body">
//                 {toastContent.message}
//                 </div>
//             </div>
//         </>

//     )
    
// }

// const mapDispatchToProps = dispatch => (
//     {
//         dispatchSetSelectedMenu: value => dispatch(setSelectedMenu(value)),
//         dispatchSetSelectedSubmenu: value => dispatch(setSelectedSubmenu(value))
//     }
// )

// const mapStateToProps = state => ({
//     selectedMenu: state.selectedMenu,
//     languageWeb: state.languageWeb,
//     entity: state.pages.Entity
// })

// export default connect(mapStateToProps, mapDispatchToProps)(EntityAdministrator)