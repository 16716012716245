import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from "moment/moment"
import { withRouter } from 'react-router-dom'
import ApiService from '../../services/ApiService'
import {
  setSelectedMenu, setSelectedSubmenu
} from '../../actions'
import './index.css'

const Login = (props) => {

    const { menu } = props

    const email = useRef('')
    const password = useRef('')

    useEffect(() => {
      window.scrollTo(0, 0)
      if(props.selectedMenu !== 'Admin') props.dispatchSetSelectedMenu('Admin')
      props.dispatchSetSelectedSubmenu('')
    },[])

    const handleAuthenticateUser = async () => {
      const userEmail = email.current.value
      const userPassword = password.current.value

      try {
        const response = await ApiService.authenticateUser({ email:userEmail, password: userPassword });
        if (response && response.token) {
          localStorage.setItem('jwtToken', response.token);
          props.history.push('/admin/home');
        } else {
          console.error('Authentication failed:', response);
        }
      } catch (error) {
        console.error('Error authenticating:', error);
      }

    }

    return (
        <div className="LoginAuthenticate">
            <div className="col-xs-8 col-md-6 col-lg-3 text-center">
            <form className="form-signin" onSubmit={event => {
              event.preventDefault()
              handleAuthenticateUser()
            }}>
              <img className='corporative-image mb-4' src={`https://www.dropbox.com/scl/fi/kqv1jguf65aua347b4s5s/00_ConArte_Logo.png?rlkey=hfeg2gnufak18135mw0hv43qq&raw=1`} height='70' alt='Corporative identity ConArte Internacional'/>
              <label for="inputEmail" className="sr-only">Email address or username</label>
              <input type="email" id="inputEmail" ref={email} className="form-control" placeholder="Email address" required="" autofocus=""/>
              <label for="inputPassword" className="sr-only">Password</label>
              <input type="password" id="inputPassword" ref={password} className="form-control" placeholder="Password" required=""/>
              <div className="checkbox mb-3 pt-2">
                <label>
                  <input type="checkbox" value="remember-me"/> Remember me
                </label>
                <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
                <h6 className="mt-5 text-muted">Copyrigth 2017-{moment().year()}</h6>
                <h6 className="text-muted mb-0 pt-1">Web Application by</h6>
                <h6 className="text-muted">© Castillo</h6>
              </div>
            </form>
            </div>
        </div>
    )

}

const mapStateToProps = state => ({
  selectedMenu: state.selectedMenu,
  menu: state.resources.Menu
})

const mapDispatchToProps = dispatch => (
  {
    dispatchSetSelectedMenu: value => dispatch(setSelectedMenu(value)),
    dispatchSetSelectedSubmenu: value => dispatch(setSelectedSubmenu(value))
  }
)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))