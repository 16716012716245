import React from 'react'
import { Link } from 'react-router-dom'
import './MobileMenu.css'

const MobileMenu = (props) => {

    const { handleActiveLink, 
      handleCalculateNewRoute, 
      handleChangeLanguage,
      information, 
      languageWeb,
      routes,
      selectedMenu, 
      selectedSubmenu, 
      traductions, 
      typeOfMobile } = props



    return (
      <div className={`${typeOfMobile==='Navbar' ? 'Mobile_Menu_Style' : 'Mobile_Menu_TopOfTheWindow_Style'} Mobile_Menu_View fixed-top`}>
        <ul className='Mobile_Menu_List_Style m-0 p-0'>
          <li>
            <div className='Mobile_Menu_Link_Padding'>
              <Link className={`nav-link effect m-0 p-0 Mobile_Menu_Link ${(selectedMenu === 'Home' && selectedSubmenu === '') ? 'Mobile_Selected_Menu_Bullet' : ''}`}
                    to={'/'}
                    onClick={handleActiveLink}
                    name={"Home"}>{information.CorporativeIdentity}
                    </Link>
            </div>
          </li>
          <li>
            <div className='Mobile_Menu_Link_Padding'>
                <Link className={`nav-link effect m-0 p-0 Mobile_Menu_Link ${(selectedMenu === 'Entity' && selectedSubmenu === '') ? 'Mobile_Selected_Menu_Bullet' : ''}`}
                      to={routes.Traductions[languageWeb].Entity}
                      onClick={handleActiveLink}
                      name={"Entity"}>{traductions[languageWeb].EntityMenu}</Link>
            </div>
          </li>
          <li>
            <div className='Mobile_Menu_Link_Padding'>
            <Link className={`nav-link effect m-0 p-0 Mobile_Menu_Link ${(selectedMenu === 'Planters' && selectedSubmenu === '') ? 'Mobile_Selected_Menu_Bullet' : ''}`}
                      to={routes.Traductions[languageWeb].Planters}
                      onClick={handleActiveLink}
                      name={"Planters"}>{traductions[languageWeb].PlantersMenu}</Link>
            </div>
          </li>
          <li>
            <div className='Mobile_Menu_Link_Padding'>
            <Link className={`nav-link effect m-0 p-0 Mobile_Menu_Sub_Link ${(selectedMenu === 'Planters' && selectedSubmenu === 'Schools') ? 'Mobile_Selected_Menu_Bullet' : ''}`}
                    to={routes.Traductions[languageWeb].SchoolsMobile}
                    onClick={handleActiveLink}
                    name={"Schools"}>{traductions[languageWeb].SchoolsMenu}</Link>
            </div>
          </li>
          <li>
            <div className='Mobile_Menu_Link_Padding'>
            <Link className={`nav-link effect m-0 p-0 Mobile_Menu_Sub_Link ${(selectedMenu === 'Planters' && selectedSubmenu === 'Extracurricular activities') ? 'Mobile_Selected_Menu_Bullet' : ''}`}
                    to={routes.Traductions[languageWeb].ExtracurricularActivitiesMobile}
                    onClick={handleActiveLink}
                    name={"ExtracurricularActivities"}>{traductions[languageWeb].ExtracurricularActivitiesMenu}</Link>
            </div>
          </li>
          <li>
            <div className='Mobile_Menu_Link_Padding'>
            <Link className={`nav-link effect m-0 p-0 Mobile_Menu_Sub_Link ${(selectedMenu === 'Planters' && selectedSubmenu === 'Formation') ? 'Mobile_Selected_Menu_Bullet' : ''}`}
                    to={routes.Traductions[languageWeb].FormationMobile}
                    onClick={handleActiveLink}
                    name={"Formation"}>{traductions[languageWeb].FormationMenu}</Link>
            </div>
          </li>
          <li>
            <div className='Mobile_Menu_Link_Padding'>
            <Link className={`nav-link effect m-0 p-0 Mobile_Menu_Sub_Link ${(selectedMenu === 'Planters' && selectedSubmenu === 'Productions') ? 'Mobile_Selected_Menu_Bullet' : ''}`}
                    to={routes.Traductions[languageWeb].ProductionsMobile}
                    onClick={handleActiveLink}
                    name={"Productions"}>{traductions[languageWeb].ProductionsMenu}</Link>
            </div>
          </li>
          <li>
            <div className='Mobile_Menu_Link_Padding'>
            <Link className={`nav-link effect m-0 p-0 Mobile_Menu_Link ${(selectedMenu === 'Network' && selectedSubmenu === '') ? 'Mobile_Selected_Menu_Bullet' : ''}`}
                    to={routes.Traductions[languageWeb].Network}
                    onClick={handleActiveLink}
                    name={"Network"}>{traductions[languageWeb].NetworkMenu}</Link>
            </div>       
          </li>
          <li>
            <div className='Mobile_Menu_Link_Padding'>
            <Link className={`nav-link effect m-0 p-0 Mobile_Menu_Link ${(selectedMenu === 'Supports' && selectedSubmenu === '') ? 'Mobile_Selected_Menu_Bullet' : ''}`}
                    to={routes.Traductions[languageWeb].Supports}
                    onClick={handleActiveLink}
                    name={"Supports"}>{traductions[languageWeb].SupportsMenu}</Link>
            </div>
          </li> 
        </ul>
        <div className='Mobile_Menu_Language_Style'>
          <Link className={`Mobile_Menu_Language_Text pr-0 ${languageWeb === 'Catalan' ? 'Mobile_Menu_Selected_Language_Style' : '' }`}
              to={handleCalculateNewRoute('Catalan')}
              onClick={handleChangeLanguage}
              name={"Catalan"}>{traductions[languageWeb].CatalanLanguage}</Link>
          <span className='Mobile_Menu_Language_Text'> / </span>
          <Link className={`Mobile_Menu_Language_Text pr-0 ${languageWeb === 'Spanish' ? 'Mobile_Menu_Selected_Language_Style' : '' }`}
              to={handleCalculateNewRoute('Spanish')}
              onClick={handleChangeLanguage}
              name={"Spanish"}>{traductions[languageWeb].SpanishLanguage}</Link>
              <span className='Mobile_Menu_Language_Text'> / </span>
          <Link className={`Mobile_Menu_Language_Text pr-0 ${languageWeb === 'English' ? 'Mobile_Menu_Selected_Language_Style' : '' }`}
              to={handleCalculateNewRoute('English')}
              onClick={handleChangeLanguage}
              name={"English"}>{traductions[languageWeb].EnglishLanguage}</Link>
        </div>
      </div>
    )
}

export default MobileMenu