import React, { useState } from 'react';

const LANGUAGES = ['Catalan', 'Spanish', 'English', 'French'];

const HomePanelAdmin = ({ traductions, handleSaveTitleAndSubtitle }) => {
  const [selectedLanguageTab, setSelectedLanguageTab] = useState('Catalan');
  const [temporalTraductions, setTemporalTraductions] = useState(traductions);

  const handleNetworkTabChange = (language) => setSelectedLanguageTab(language);

  const handleChangeInformation = (event) => {
    const { name, value } = event.target;

    setTemporalTraductions((prevInformation) => ({
      ...prevInformation,
      [selectedLanguageTab]: {
        ...prevInformation[selectedLanguageTab],
        [name]: value,
      },
    }));
  };

  const renderLanguageSection = (language) => (
    <div
      className={`tab-pane fade show ${selectedLanguageTab === language && 'active'}`}
      id={`${language}-tab`}
      role="tabpanel"
      aria-labelledby={`${language}-tab-button`}
    >
      <div className="row mb-2">
        <div className="col-md-3">
          <div className="form-outline">
            <label className="form-label" htmlFor={`${language}-title`}>Títol</label>
            <input
              type="text"
              id={`${language}-title`}
              className="form-control"
              name="Carousel"
              value={temporalTraductions[selectedLanguageTab].Carousel}
              onChange={handleChangeInformation}
            />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-3">
          <div className="form-outline">
            <label className="form-label" htmlFor={`${language}-subtitle`}>Subtítol</label>
            <input
              type="text"
              id={`${language}-subtitle`}
              className="form-control"
              name="Highlight"
              value={temporalTraductions[selectedLanguageTab].Highlight}
              onChange={handleChangeInformation}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const printLanguage = (language) => {
    if (language === 'Catalan') return 'Català';
    if (language === 'Spanish') return 'Castellà';
    if (language === 'English') return 'Anglès';
    if (language === 'French') return 'Francés';
  };

  return (
    <div className="card m-4">
      <div className="card-header d-flex justify-content-between align-items-center py-1">
        <span className="me-auto">TÍTOL</span>
        <button 
          type="button" 
          onClick={() => handleSaveTitleAndSubtitle(temporalTraductions)} 
          className="btn btn-success btn-rounded btn-sm"
        >
          <i className="bi bi-save"></i>
          <span style={{ paddingLeft: '10px' }}>Guardar</span>
        </button>
      </div>
      <div className="card-body">
        <ul className="nav nav-tabs mb-3" role="tablist">
          {LANGUAGES.map((language) => (
            <li className="nav-item" role="presentation" key={language}>
              <button
                className={`nav-link ${selectedLanguageTab === language && 'active'}`}
                id={`${language}-tab-button`}
                data-mdb-toggle="tab"
                role="tab"
                aria-controls={`${language}-tab`}
                aria-selected={selectedLanguageTab === language}
                onClick={() => handleNetworkTabChange(language)}
              >
                { printLanguage(language) }
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content">
          {LANGUAGES.map((language) => renderLanguageSection(language))}
        </div>
      </div>
    </div>
  );
};

export default HomePanelAdmin;
