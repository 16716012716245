import React, { useState } from 'react';

const CarouselPanelAdmin = ({ images, handleSaveCarousel }) => {

  const [temporalImages, setTemporalImages] = useState(images);

  const addNewImageToCarousel = () => {
    setTemporalImages(prevImages => [...prevImages, ''])
  }

  const handleChangeImageUrl = (index, event) => {
    const { value } = event.target
    setTemporalImages(prevImages => {
      const updatedImages = [...prevImages]
      updatedImages[index] = value
      return updatedImages
    })

  }

  const swapImages = (index1, index2) => {
    setTemporalImages(prevImages => {
      const newImages = [...prevImages]
      [newImages[index1], newImages[index2]] = [newImages[index2], newImages[index1]]
      return newImages;
    })
  }

  const removeImageCarousel = index => {
    setTemporalImages(prevImages => {
      if (prevImages.length > 1 && index < prevImages.length - 1) {
        // Verifica que hay más de una imagen y que el índice no es el de la última imagen
        return prevImages.filter((_, i) => i !== index);
      } else {
        // No se permite eliminar la última imagen
        return prevImages;
      }
    });
  };
  

  return (
    <div className="card m-4">
      <div className="card-header d-flex justify-content-between align-items-center py-1">
        <span className="me-auto">CARRUSEL D'IMATGES</span>
        <button type="button" onClick={() => handleSaveCarousel(temporalImages)} className="btn btn-success btn-rounded btn-sm">
          <i className="bi bi-save"></i> <span style={{ paddingLeft: '10px' }}>Guardar</span>
        </button>
      </div>
      <div className="card-body pt-0">
        <div className='row mt-2 ml-1 mr-1'>
          <div className="d-flex justify-content-end align-items-end">
            <div className='text-end'>
                <button className="btn btn-sm btn-primary mt-3" style={{ marginLeft: 'auto' }} onClick={addNewImageToCarousel}>
                <i className="bi bi-plus"></i> <span style={{ paddingLeft: '10px' }}>Afegir imatge</span>
                </button>
            </div>
          </div>
          <div className="table-responsive mt-2">
            <table className="table table-sm">
              <thead className="table-primary">
                <tr>
                  <th className="border-top-0 border-bottom-0 text-center" scope="col" style={{ width: '30px' }}>Posició</th>
                  <th className="border-top-0 border-bottom-0" scope="col">Enllaç</th>
                  <th className="border-top-0 border-bottom-0 text-right" colSpan="3"></th>
                </tr>
              </thead>
              <tbody>
                {temporalImages.map((image, index) => (
                  <tr key={index} className="animated fadeIn">
                    <td className="text-center">{index + 1}</td>
                    <td>
                      <textarea
                        className="form-control"
                        id="textAreaExample"
                        rows={2}
                        name="ImageURL"
                        value={image}
                        onChange={(e) => handleChangeImageUrl(index, e)}
                      ></textarea>
                    </td>
                    <td className="text-end" style={{ width: '130px' }}>
                      <button
                        className="btn btn-sm btn-primary me-1"
                        disabled={index === 0}
                        onClick={() => swapImages(index, index - 1)}
                      >
                        <i className="bi bi-arrow-up"></i>
                      </button>
                      <button
                        className="btn btn-sm btn-primary me-1 ml-2"
                        disabled={index === temporalImages.length - 1}
                        onClick={() => swapImages(index, index + 1)}
                      >
                        <i className="bi bi-arrow-down"></i>
                      </button>
                      <button
                        className="btn btn-sm btn-danger ml-2"
                        onClick={() => removeImageCarousel(index)}
                      >
                        <i className="bi bi-trash3"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselPanelAdmin;