import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  setSelectedMenu,
  setSelectedSubmenu
} from '../../../actions'
import ApiService from '../../../services/ApiService'
import SchoolsPanelAdmin from './partials/SchoolsPanelAdmin'
import Projectes from './partials/SchoolsProjectsAdmin'
import SchoolsExtraPanelAdmin from './partials/SchoolsExtraPanelAdmin'


const SchoolsAdministrator = (props) => {

    const { schools } = props

    const [temporalSchoolsPage, setTemporalSchoolsPage] = useState(schools);
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState({ title: "", message: "", color: "" });

    useEffect(() => {
        window.scrollTo(0, 0)
        if(props.selectedMenu !== 'Admin') props.dispatchSetSelectedMenu('Admin')
        props.dispatchSetSelectedSubmenu('SchoolsAdministrator')
    },[])


    const handleSaveTitleAndSubtitle = async (newTraductions) => {
        const newSchoolsPage = Object.assign({}, temporalSchoolsPage)
        newSchoolsPage.Traductions = newTraductions
        newSchoolsPage.nameOfThePage = 'Schools'
        try {
          const response = await ApiService.updatePage(newSchoolsPage);
          if (response) {
            console.log("Page updated successfully:", response);
            setToastContent({ title: "Success", message: "Page updated successfully.", color: "success" });
            setShowToast(true);
          } else {
            console.log("Failed to update the page.");
            setToastContent({ title: "Error", message: "Failed to update the page.", color: "danger" });
            setShowToast(true);
          }
        } catch (error) {
          console.error("Error updating the page:", error);
          setToastContent({ title: "Error", message: "Error updating the page.", color: "danger" });
          setShowToast(true);
        }
    }

    const handleSaveProjects = async (projects) => {
        const newSchoolsPage = Object.assign({}, temporalSchoolsPage)
        newSchoolsPage.Projects = projects
        newSchoolsPage.nameOfThePage = 'Schools'

        try {
          const response = await ApiService.updatePage(newSchoolsPage)
          if (response) {
            console.log("Page updated successfully:", response);
            setToastContent({ title: "Success", message: "Page updated successfully.", color: "success" });
            setShowToast(true);
          } else {
            console.log("Failed to update the page.");
            setToastContent({ title: "Error", message: "Failed to update the page.", color: "danger" });
            setShowToast(true);
          }
        } catch (error) {
          console.error("Error updating the page:", error);
          setToastContent({ title: "Error", message: "Error updating the page.", color: "danger" });
          setShowToast(true);
        }
    }
    

    return (
        <>
           <SchoolsPanelAdmin traductions={schools.Traductions} handleSaveTitleAndSubtitle={handleSaveTitleAndSubtitle} />
           <Projectes projects={schools.Projects} handleSaveProjects={handleSaveProjects} />
           <SchoolsExtraPanelAdmin  traductions={schools.Traductions} handleSaveTitleAndSubtitle={handleSaveTitleAndSubtitle}></SchoolsExtraPanelAdmin>
           <div className={`toast align-top bg-${toastContent.color} ${showToast ? 'show' : ''}`} role="alert" aria-live="assertive" aria-atomic="true" style={{position: 'fixed', top: '20px', right: '20px', zIndex: 1050}}>
                <div className="toast-header">
                <strong className="me-auto">{toastContent.title}</strong>
                <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast(false)}></button>
                </div>
                <div className="toast-body">
                {toastContent.message}
                </div>
            </div>
        </>

    )
    
}

const mapDispatchToProps = dispatch => (
    {
        dispatchSetSelectedMenu: value => dispatch(setSelectedMenu(value)),
        dispatchSetSelectedSubmenu: value => dispatch(setSelectedSubmenu(value))
    }
)

const mapStateToProps = state => ({
    selectedMenu: state.selectedMenu,
    languageWeb: state.languageWeb,
    schools: state.pages.Schools
})

export default connect(mapStateToProps, mapDispatchToProps)(SchoolsAdministrator)