import { lazy } from 'react'
import MenuAdministrator from '../components/Administration/MenuAdministrator'
import FooterAdministrator from '../components/Administration/Footer'
import PlantersAdministrator from '../components/Administration/PlantersAdministrator'
import HomeAdministrator from '../components/Administration/HomeAdministrator'
import EntityAdministrator from '../components/Administration/EntityAdministrator'
import NetworkAdministrator from '../components/Administration/NetworkAdministrator'
import SupportAdministrator from '../components/Administration/SupportAdministrator'
import SchoolsAdministrator from '../components/Administration/SchoolsAdministrator'
import ExtracurricularActivitiesAdministrator from '../components/Administration/ExtracurricularActivitiesAdministrator'
import ProductionsAdministrator from '../components/Administration/ProductionsAdministrator'
import FormationAdministrator from '../components/Administration/FormationAdministrator'


const MainPage = lazy(() => import('../components/Pages/Home'))
const EntityPage = lazy(() => import('../components/Pages/Entity'))
const PlantersPage = lazy(() => import('../components/Pages/Planters'))
const NetworkPage = lazy(() => import('../components/Pages/Network'))
const SupportsPage = lazy(() => import('../components/Pages/Supports'))
const ArbrarPage = lazy(() => import('../components/Pages/Arbrar'))

const adminRoutes = [
    { path: '/admin/home', component: HomeAdministrator },
    { path: '/admin/entitat', component: EntityAdministrator },
    { path: '/admin/planters', component: PlantersAdministrator },
    { path: '/admin/centres-educatius', component: SchoolsAdministrator },
    { path: '/admin/activitats-extraescolars', component: ExtracurricularActivitiesAdministrator },
    { path: '/admin/formacio', component: FormationAdministrator },
    { path: '/admin/produccions', component: ProductionsAdministrator },
    { path: '/admin/en-xarxa', component: NetworkAdministrator },
    { path: '/admin/suports', component: SupportAdministrator },
    { path: '/admin/menu', component: MenuAdministrator },
    { path: '/admin/footer', component: FooterAdministrator }
];

const publicRoutes = [
    { path: '/', component: MainPage, exact: true },
    { path: '/entitat', component: EntityPage, exact: true },
    { path: '/entidad', component: EntityPage, exact: true },
    { path: '/entity', component: EntityPage, exact: true },
    { path: '/planters', component: PlantersPage, exact: true },
    { path: '/planters/centreseducatius', component: PlantersPage, exact: true },
    { path: '/planters/centroseducativos', component: PlantersPage, exact: true },
    { path: '/planters/schools', component: PlantersPage, exact: true },
    { path: '/planters/activitatsextraescolars', component: PlantersPage, exact: true },
    { path: '/planters/actividadesextraescolares', component: PlantersPage, exact: true },
    { path: '/planters/extracurricularactivities', component: PlantersPage, exact: true },
    { path: '/planters/formacio', component: PlantersPage, exact: true },
    { path: '/planters/formacion', component: PlantersPage, exact: true },
    { path: '/planters/formation', component: PlantersPage, exact: true },
    { path: '/planters/produccions', component: PlantersPage, exact: true },
    { path: '/planters/producciones', component: PlantersPage, exact: true },
    { path: '/planters/productions', component: PlantersPage, exact: true },
    { path: '/enxarxa', component: NetworkPage, exact: true },
    { path: '/redes', component: NetworkPage, exact: true },
    { path: '/network', component: NetworkPage, exact: true },
    { path: '/suports', component: SupportsPage, exact: true },
    { path: '/apoyos', component: SupportsPage, exact: true },
    { path: '/supports', component: SupportsPage, exact: true },
    { path: '/projectearbrar', component: ArbrarPage, exact: true },
    { path: '/proyectoarbrar', component: ArbrarPage, exact: true }
];

export { adminRoutes, publicRoutes };
