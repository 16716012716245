import { createStore } from 'redux'
import { informationReducer } from '../../reducers/informationReducer'

const initialState = {
  languageWeb: 'Catalan',
  selectedMenu: 'Home',
  selectedSubmenu: '',
  arbrarPosition: '',
  isTopOnTheWindow: true,
  isAnimation: true,
  pages: {
    Home: {},
    Entity: {},
    Network: {},
    Planters: {},
    Schools: {},
    ExtracurricularActivities: {},
    Formation: {},
    Productions: {},
    Support: {},
    Arbrar: {}
  },
  resources: {
    Footer: {},
    Menu: {},
    Routes: {
      Traductions: {
        Catalan: {
          Home: '/',
          Network: '/enxarxa'
        },
        English: {
          Home: '/'
        },
        French: {
          Home: '/'
        },
        Spanish: {
          Home: '/'
        }
      }
    }
  }
}

export const store = createStore(
    informationReducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)