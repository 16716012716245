import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { isMobileOnly, isMobileSafari } from 'react-device-detect'
import MobileMenu from '../MobileMenu'
import Submenu from '../Submenu'
import './Navbar.css'

const Navbar = (props) => {

  const { handleActiveLink, handleActiveSubmenu, handleCalculateNewRoute, handleChangeLanguage, information, languageWeb, routes, selectedMenu, selectedSubmenu, traductions } = props

  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false)
  const [typeOfMobile] = useState('Navbar')

  const handleActiveLinkMobile = () => {
    handleShowMobileMenu()
  }

  const renderMobileVersion = () => {
    return (
      <>
      <div className='row m-0 Navbar_Mobile_Style Navbar_Mobile_View fixed-top'>
        <div className="col-9 m-0 p-0">
          <div className='Navbar_Mobile_Identity_Padding'>
            {
              !isShowMobileMenu && 
              <Link to={`/`}
                    onClick={handleActiveLink}
                    name={"Home"}>
                <span className='Navbar_Mobile_CorporativeIdentity_Text'>{information.CorporativeIdentity}</span>
              </Link>
            }
          </div>
        </div>  
        <div className="col-3 m-0 p-0">
          <div className='Navbar_Mobile_Menu_Padding_Icon'>
            <button className={`${isShowMobileMenu || isMobileSafari ? 'Navbar_Mobile_Button_Without_Circle_Style' : 'Navbar_Mobile_Button_Circle_Style'}  Navbar_Mobile_Icon`} style={{float: 'right'}} onClick={() => handleShowMobileMenu()}>
              {!isShowMobileMenu && <i className="bi bi-list Navbar_Mobile_Icon"></i>}
              {isShowMobileMenu && <i className="bi bi-x-lg Navbar_Mobile_Icon"></i>}
            </button>
          </div>
        </div>
      </div>
      {
        isShowMobileMenu && 
        <MobileMenu 
          handleActiveLink={handleActiveLinkMobile}
          handleCalculateNewRoute={handleCalculateNewRoute}
          handleChangeLanguage={handleChangeLanguage} 
          information={information}
          languageWeb={languageWeb} 
          routes={routes}
          selectedMenu={selectedMenu}
          selectedSubmenu={selectedSubmenu}
          traductions={traductions}
          typeOfMobile={typeOfMobile} />
      }
      </>
    )
  }
  
  const renderDesktopVersion = () => {
    return (
      <>
      <div className="row m-0 fixed-top Navbar_Style Navbar_Desktop_View">
        <div className="col-2 m-0 Navbar_CorporativeIdentity_Paddding">
          <Link className="Navbar_IdentityCorporative_Link"
                to={`/`}
                onClick={handleActiveLink}
                name={"Home"}>
            <span className='Navbar_CorporativeIdentity_Text'>{information.CorporativeIdentity}</span>
          </Link>
        </div>
        <div className="col-8 m-0 p-0 Navbar_Menu_JustifyContent">
          <ul className='Navbar_List_Style'>
            <li className="nav-item">
              <Link className={`nav-link effect Navbar_LinkText ${selectedMenu === 'Entity' ? 'Navbar_LinkText_Bullet' : 'Navbar_LinkText_Bullet_Hover'}`}
                    to={routes.Traductions[languageWeb].Entity}
                    onClick={handleActiveLink}
                    disabled={true}
                    name={"Entity"}>{traductions[languageWeb].EntityMenu}</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link effect Navbar_LinkText ${selectedMenu === 'Planters' ? 'Navbar_LinkText_Bullet' : 'Navbar_LinkText_Bullet_Hover'}`}
                    to={routes.Traductions[languageWeb].Planters}
                    onClick={handleActiveLink}
                    disabled={true}
                    name={"Planters"}>{traductions[languageWeb].PlantersMenu}</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link effect Navbar_LinkText ${selectedMenu === 'Network' ? 'Navbar_LinkText_Bullet' : 'Navbar_LinkText_Bullet_Hover'}`}
                    to={routes.Traductions[languageWeb].Network}
                    onClick={handleActiveLink}
                    disabled={true}
                    name={"Network"}>{traductions[languageWeb].NetworkMenu}</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link effect Navbar_LinkText ${selectedMenu === 'Supports' ? 'Navbar_LinkText_Bullet' : 'Navbar_LinkText_Bullet_Hover'}`}
                    to={routes.Traductions[languageWeb].Supports}
                    onClick={handleActiveLink}
                    disabled={true}
                    name={"Supports"}>{traductions[languageWeb].SupportsMenu}</Link>
            </li>
          </ul>
        </div>
        <div className="col-2 m-0 p-0">
          <ul className={"Navbar_List_Style Navbar_TopOfTheWindow_UL_TextAlign"}>
            <li className="nav-item">
              <Link className={`nav-link effect Navbar_LanguageText pr-0 ${languageWeb === 'Catalan' ? 'Navbar_Language_Selected' : '' }`}
                    to={handleCalculateNewRoute('Catalan')}
                    onClick={handleChangeLanguage}
                    disabled={true}
                    name={"Catalan"}>{traductions[languageWeb].CatalanLanguage}</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link effect Navbar_LanguageText pr-0 ${languageWeb === 'Spanish' ? 'Navbar_Language_Selected' : '' }`}
                    to={handleCalculateNewRoute('Spanish')}
                    onClick={handleChangeLanguage}
                    disabled={true}
                    name={"Spanish"}>{traductions[languageWeb].SpanishLanguage}</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link effect Navbar_LanguageText pr-0 ${languageWeb === 'English' ? 'Navbar_Language_Selected' : '' }`}
                    to={handleCalculateNewRoute('English')}
                    onClick={handleChangeLanguage}
                    disabled={true}
                    name={"English"}>{traductions[languageWeb].EnglishLanguage}</Link>
            </li>
          </ul>
        </div>
      </div>
    {
      !isMobileOnly &&
      selectedMenu === 'Planters' &&
      <Submenu 
        handleActiveSubmenu={handleActiveSubmenu} 
        languageWeb={languageWeb} 
        routes={routes} 
        selectedSubmenu={selectedSubmenu} 
        traductions={traductions}/>
    }
    </>
    )
  }

  const handleShowMobileMenu = () => {
    setIsShowMobileMenu(!isShowMobileMenu)
  }

  return (
    <>
      {renderDesktopVersion()}
      {renderMobileVersion()}
    </>
  )
}

export default Navbar